define('ui/models/secret', ['exports', 'ember-api-store/models/resource'], function (exports, _resource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _resource.default.extend({
    router: Ember.inject.service(),

    state: 'active',
    canClone: true,

    firstKey: Ember.computed.alias('keys.firstObject'),
    keys: Ember.computed('data', function () {
      return Object.keys(Ember.get(this, 'data') || {}).sort();
    }),

    actions: {
      edit: function edit() {
        Ember.get(this, 'router').transitionTo('authenticated.project.secrets.detail.edit', Ember.get(this, 'id'));
      },
      clone: function clone() {
        Ember.get(this, 'router').transitionTo('authenticated.project.secrets.new', {
          queryParams: {
            id: Ember.get(this, 'id'),
            type: Ember.get(this, 'type')
          }
        });
      }
    }

  });
});