define('ui/helpers/pretty-cron', ['exports', 'shared/helpers/pretty-cron', 'npm:prettycron'], function (exports, _prettyCron, _npmPrettycron) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _prettyCron.default;
    }
  });
});