define('ui/models/sourcecodecredential', ['exports', 'ember-api-store/models/resource'], function (exports, _resource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _resource.default.extend({
    type: 'sourcecodecredential',
    username: function () {
      return Ember.get(this, 'displayName');
    }.property('displayName'),
    profilePicture: function () {
      return Ember.get(this, 'avatarUrl');
    }.property('avatarUrl'),
    profileUrl: function () {
      return Ember.get(this, 'htmlUrl');
    }.property('htmlUrl')
  });
});