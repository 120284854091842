define('ui/models/catalogtemplate', ['exports', 'ember-api-store/models/resource', 'ui/utils/parse-externalid', 'ui/utils/constants'], function (exports, _resource, _parseExternalid, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _resource.default.extend({
    catalog: Ember.inject.service(),

    type: 'catalogTemplate',

    externalId: function () {
      var id = this.get('templateVersionId') || this.get('templateId');

      if (id) {
        return _constants.default.EXTERNAL_ID.KIND_CATALOG + _constants.default.EXTERNAL_ID.KIND_SEPARATOR + id;
      }
    }.property('templateVersionId', 'templateId'),

    externalIdInfo: function () {
      return (0, _parseExternalid.parseExternalId)(this.get('externalId'));
    }.property('externalId'),

    // These only works if the templates have already been loaded elsewhere...
    catalogTemplate: function () {
      return this.get('catalog').getTemplateFromCache(this.get('externalIdInfo.templateId'));
    }.property('externalIdInfo.templateId'),

    icon: function () {
      var tpl = this.get('catalogTemplate');

      if (tpl) {
        return tpl.linkFor('icon');
      }
    }.property('catalogTemplate'),

    categories: function () {
      var tpl = this.get('catalogTemplate');

      if (tpl) {
        return tpl.get('categories') || [];
      }

      return [];
    }.property('catalogTemplate.categories')
  });
});