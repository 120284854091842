define('ui/utils/graph-area', ['exports', 'shared/utils/graph-area'], function (exports, _graphArea) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _graphArea.default;
    }
  });
});