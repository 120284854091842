define('ui/components/progress-bar-multi/component', ['exports', 'ui/components/progress-bar-multi/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function toPercent(value, min, max) {
    value = Math.max(min, Math.min(max, value));
    var per = value / (max - min) * 100; // Percent 0-100

    per = Math.floor(per * 100) / 100; // Round to 2 decimal places

    return per;
  }

  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'div',
    classNames: ['progress-bar-multi'],

    values: null,
    colorKey: 'color',
    labelKey: 'label',
    valueKey: 'value',
    tooltipValues: null,
    min: 0,
    max: null,
    minPercent: 10,
    zIndex: null,
    tooltipTemplate: 'tooltip-static',
    tooltipArrayOrString: 'string',

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var colorKey = Ember.get(this, 'colorKey');
      var labelKey = Ember.get(this, 'labelKey');
      var valueKey = Ember.get(this, 'valueKey');

      var valueDep = 'values.@each.{' + colorKey + ',' + labelKey + ',' + valueKey + '}';

      Ember.defineProperty(this, 'pieces', Ember.computed('min', 'max', valueDep, function () {
        var min = Ember.get(_this, 'min');
        var max = Ember.get(_this, 'max');

        var out = [];

        (Ember.get(_this, 'values') || []).forEach(function (obj) {
          out.push({
            color: Ember.get(obj, colorKey),
            label: Ember.get(obj, labelKey),
            value: Ember.get(obj, valueKey)
          });
        });

        if (!max) {
          max = 100;
          if (out.length) {
            max = out.map(function (x) {
              return x.value;
            }).reduce(function (a, b) {
              return a + b;
            });
          }
        }

        var sum = 0;
        var minPercent = Ember.get(_this, 'minPercent');

        out.forEach(function (obj) {
          var per = Math.max(minPercent, toPercent(obj.value, min, max));

          obj.percent = per;
          sum += per;
        });

        // If the sum is bigger than 100%, take it out of the biggest piece.
        if (sum > 100) {
          out.sortBy('percent').reverse()[0].percent -= sum - 100;
        }

        out.forEach(function (obj) {
          obj.css = ('width: ' + obj.percent + '%').htmlSafe();
        });

        return out;
      }));

      valueDep = 'tooltipValues.@each.{' + labelKey + ',' + valueKey + '}';
      Ember.defineProperty(this, 'tooltipContent', Ember.computed(valueDep, function () {
        var labelKey = Ember.get(_this, 'labelKey');
        var valueKey = Ember.get(_this, 'valueKey');

        var out = [];

        (Ember.get(_this, 'tooltipValues') || []).forEach(function (obj) {
          if (Ember.get(_this, 'tooltipArrayOrString') === 'string') {
            out.push(Ember.get(obj, labelKey) + ': ' + Ember.get(obj, valueKey));
          } else {
            out.push({
              label: Ember.get(obj, labelKey),
              value: Ember.get(obj, valueKey)
            });
          }
        });

        return Ember.get(_this, 'tooltipArrayOrString') === 'string' ? out.join('\n') : out;
      }));
    },
    didInsertElement: function didInsertElement() {
      this.zIndexDidChange();
    },


    zIndexDidChange: Ember.observer('zIndex', function () {
      this.$().css('zIndex', Ember.get(this, 'zIndex') || 'inherit');
    })

  });
});