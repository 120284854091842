define('ui/mixins/model-alert', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    router: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),
    intl: Ember.inject.service(),
    pageScope: Ember.computed.reads('scope.currentPageScope'),

    canClone: false,

    relevantState: Ember.computed('combinedState', 'alertState', function () {
      return this.get('combinedState') || this.get('alertState') || 'unknown';
    }),

    init: function init() {
      var stateMap = {
        'muted': {
          icon: 'icon icon-tag',
          color: 'text-default'
        },
        'alerting': {
          icon: 'icon icon-tag',
          color: 'text-error'
        },
        'inactive': {
          icon: 'icon icon-tag',
          color: 'text-warning'
        }
      };

      this.constructor.stateMap = stateMap;
      this._super.apply(this, arguments);
    },


    displayTargetType: Ember.computed('targetType', function () {
      var t = Ember.get(this, 'targetType');
      var intl = Ember.get(this, 'intl');

      return intl.t('alertPage.targetTypes.' + t);
    }),

    resourceKind: Ember.computed('targetEvent.resourceKind', function () {
      var rk = Ember.get(this, 'targetEvent.resourceKind');

      return Ember.get(this, 'intl').t('alertPage.resourceKinds.' + rk);
    }),

    firstRecipient: Ember.computed('recipients.length', function () {
      var recipient = (Ember.get(this, 'recipients') || []).get('firstObject');

      if (recipient && Ember.get(recipient, 'notifierId')) {
        var notifierId = Ember.get(recipient, 'notifierId');

        if (!notifierId) {
          return null;
        }

        var notifier = Ember.get(this, 'globalStore').all('notifier').filterBy('id', notifierId).get('firstObject');

        if (!notifier) {
          return null;
        }

        return '' + notifier.get('displayNameAndType');
      }

      return null;
    }),

    displayRecipient: Ember.computed('firstRecipient', 'model.recipients.length', function () {
      var len = Ember.get(this, 'recipients.length');
      var firstRecipient = Ember.get(this, 'firstRecipient');
      var intl = Ember.get(this, 'intl');
      var out = intl.t('alertPage.na');

      if (len === 0) {
        // out = na;
      } else if (len === 1) {
        out = firstRecipient;
      } else {
        out = len + ' ' + intl.t('alertPage.items');
      }

      return out;
    }),

    nodeName: Ember.computed('targetNode.nodeId', function () {
      var id = Ember.get(this, 'targetNode.nodeId');

      if (!id) {
        return null;
      }
      var node = Ember.get(this, 'globalStore').all('node').filterBy('id', id).get('firstObject');

      if (!node) {
        return null;
      }

      return node.get('displayName');
    }),

    actions: {
      edit: function edit() {
        var ps = Ember.get(this, 'pageScope');
        var id = Ember.get(this, 'id');

        if (ps === 'cluster') {
          Ember.get(this, 'router').transitionTo('authenticated.cluster.alert.edit', id);
        } else if (ps === 'project') {
          Ember.get(this, 'router').transitionTo('authenticated.project.alert.edit', id);
        }
      },
      mute: function mute() {
        return this.doAction('mute');
      },
      unmute: function unmute() {
        return this.doAction('unmute');
      },
      activate: function activate() {
        return this.doAction('activate');
      },
      deactivate: function deactivate() {
        return this.doAction('deactivate');
      }
    },

    availableActions: Ember.computed('actionLinks.{mute,unmute,activate,deactivate}', function () {
      // let al = this.get('actionlinks');
      var state = this.get('alertState');

      return [{
        label: 'action.mute',
        action: 'mute',
        enabled: state === 'alerting',
        icon: 'icon icon-mute',
        bulkable: true
      }, {
        label: 'action.unmute',
        action: 'unmute',
        icon: 'icon icon-unmute',
        enabled: state === 'muted',
        bulkable: true
      }, {
        label: 'action.deactivate',
        action: 'deactivate',
        icon: 'icon icon-pause',
        enabled: state === 'active',
        bulkable: true
      }, {
        label: 'action.activate',
        icon: 'icon icon-play',
        action: 'activate',
        enabled: state === 'inactive',
        bulkable: true
      }];
    })
  });
});