define('ui/components/cluster-dashboard/component', ['exports', 'ui/utils/constants', 'ui/components/cluster-dashboard/template'], function (exports, _constants, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    scope: Ember.inject.service(),

    layout: _template.default,

    nodes: null,
    components: null,
    componentStatuses: Ember.computed.alias('scope.currentCluster.componentStatuses'),

    init: function init() {
      this._super.apply(this, arguments);
      this.setComponents();
    },


    updateComponentsStatus: Ember.observer('componentStatuses.@each.conditions', 'nodes.@each.{state}', function () {
      this.setComponents();
    }),

    showDashboard: Ember.computed('scope.currentCluster.isReady', 'nodes.[]', function () {
      return Ember.get(this, 'nodes').length && Ember.get(this, 'scope.currentCluster.isReady');
    }),

    inactiveNodes: Ember.computed('nodes.@each.state', function () {
      return Ember.get(this, 'nodes').filter(function (n) {
        return _constants.default.ACTIVEISH_STATES.indexOf(Ember.get(n, 'state')) === -1;
      });
    }),

    unhealthyComponents: Ember.computed('componentStatuses.@each.conditions', function () {
      return (Ember.get(this, 'componentStatuses') || []).filter(function (s) {
        return !s.conditions.any(function (c) {
          return c.status === 'True';
        });
      });
    }),

    setComponents: function setComponents() {
      var etcd = this.getEtcdComponent();
      var controller = this.getControllerComponent();
      var scheduler = this.getSchedulerComponent();
      var node = this.getNodeComponent();

      Ember.set(this, 'components', [etcd, controller, scheduler, node]);
    },
    getEtcdComponent: function getEtcdComponent() {
      return {
        name: Ember.get(this, 'intl').t('clusterDashboard.etcd'),
        healthy: this.isHealthy('etcd')
      };
    },
    getControllerComponent: function getControllerComponent() {
      return {
        name: Ember.get(this, 'intl').t('clusterDashboard.controllerManager'),
        healthy: this.isHealthy('controller-manager')
      };
    },
    getSchedulerComponent: function getSchedulerComponent() {
      return {
        name: Ember.get(this, 'intl').t('clusterDashboard.scheduler'),
        healthy: this.isHealthy('scheduler')
      };
    },
    getNodeComponent: function getNodeComponent() {
      return {
        name: Ember.get(this, 'intl').t('clusterDashboard.node'),
        healthy: Ember.get(this, 'inactiveNodes.length') === 0
      };
    },
    isHealthy: function isHealthy(field) {
      return (Ember.get(this, 'componentStatuses') || []).filter(function (s) {
        return s.name.startsWith(field);
      }).any(function (s) {
        return s.conditions.any(function (c) {
          return c.status === 'True';
        });
      });
    }
  });
});