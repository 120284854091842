define('ui/catalog-tab/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['category', 'catalogId'],
    category: '',
    catalogId: ''
  });
});