define('ui/components/node-gauges/component', ['exports', 'shared/utils/parse-unit', 'ui/components/node-gauges/template'], function (exports, _parseUnit, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),

    layout: _template.default,

    nodes: null,

    gauges: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      setTimeout(function () {
        _this.setDashboard();
      }, 150);
    },


    updateDashboard: Ember.observer('nodes.@each.{allocatable,requested}', 'intl.locale', function () {
      this.setDashboard();
    }),

    setDashboard: function setDashboard() {
      var cpuGauge = this.getCpuGauge();
      var memoryGauge = this.getMemoryGauge();
      var podsGauge = this.getPodsGauge();

      this.set('gauges', [cpuGauge, memoryGauge, podsGauge]);
    },
    getCpuGauge: function getCpuGauge() {
      return this.getGauge('cpu', function (u, t) {
        return (0, _parseUnit.formatSi)(u, 1000, '', '', 0, (0, _parseUnit.exponentNeeded)(t), 1).replace(/\s.*$/, '');
      }, function (t) {
        return (0, _parseUnit.formatSi)(t, 1000, '', '', 0, (0, _parseUnit.exponentNeeded)(t), 1);
      }, 'reserved');
    },
    getMemoryGauge: function getMemoryGauge() {
      return this.getGauge('memory', function (u, t) {
        return (0, _parseUnit.formatSi)(u, 1024, '', '', 0, (0, _parseUnit.exponentNeeded)(t), 1).replace(/\s.*$/, '');
      }, function (t) {
        return (0, _parseUnit.formatSi)(t, 1024, 'iB', 'B', 0, (0, _parseUnit.exponentNeeded)(t), 1);
      }, 'reserved');
    },
    getPodsGauge: function getPodsGauge() {
      return this.getGauge('pods', function (u, t) {
        return (0, _parseUnit.formatSi)(u, 1000, '', '', 0, (0, _parseUnit.exponentNeeded)(t), 1).replace(/\s.*$/, '');
      }, function (t) {
        return (0, _parseUnit.formatSi)(t, 1000, '', '', 0, (0, _parseUnit.exponentNeeded)(t), 1);
      }, 'used');
    },
    getGauge: function getGauge(field, usedFormatCb, totalFormatCb, keyword) {
      var nodes = this.getNodes(field);

      return {
        value: this.getValue(nodes),
        title: this.get('intl').t('clusterDashboard.' + field),
        subtitle: this.getSubtitle(nodes, totalFormatCb, usedFormatCb, keyword),
        ticks: this.getTicks(nodes)
      };
    },
    getTicks: function getTicks(nodes) {
      var filtered = [];

      if (nodes.length > 0) {
        var min = nodes[0].value;
        var max = nodes[nodes.length - 1].value;

        filtered = nodes.filter(function (node) {
          return node.value === min || node.value === max;
        });
      }
      var ticks = [];

      filtered.forEach(function (node) {
        var found = ticks.find(function (tick) {
          return tick.value === Math.round(node.value);
        });

        if (found) {
          found.labels.push(node.node.nodeName);
        } else {
          ticks.push({
            value: Math.round(node.value),
            labels: [node.node.nodeName]
          });
        }
      });

      return ticks;
    },
    getNodes: function getNodes(field) {
      return (this.get('nodes') || []).map(function (node) {
        var tValue = node.allocatable && node.allocatable[field] ? node.allocatable[field] : '0';
        var uValue = node.requested && node.requested[field] ? node.requested[field] : '0';

        var total = (0, _parseUnit.parseSi)(tValue);
        var used = (0, _parseUnit.parseSi)(uValue);

        if (isNaN(total) || isNaN(used)) {
          used = 0;
          total = 0;
        }

        var value = 0;

        if (total > 0) {
          value = used * 100 / total;
        }

        return {
          node: node,
          used: used,
          total: total,
          value: value
        };
      }).sortBy('value');
    },
    getValue: function getValue(nodes) {
      var used = 0;
      var total = 0;

      nodes.forEach(function (node) {
        total += node.total;
        used += node.used;
      });
      var value = Math.round(used * 100 / total);

      return isNaN(value) ? 0 : value;
    },
    getSubtitle: function getSubtitle(nodes, totalCb, usedCb, keyword) {
      var used = 0;
      var total = 0;

      nodes.forEach(function (node) {
        total += node.total;
        used += node.used;
      });

      return this.get('intl').t('clusterDashboard.subtitle.' + keyword, {
        used: usedCb ? usedCb(used, total) : used,
        total: totalCb ? totalCb(total) : total
      });
    }
  });
});