define('ui/helpers/linkify', ['exports', 'shared/helpers/linkify', 'npm:url-regex'], function (exports, _linkify, _npmUrlRegex) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _linkify.default;
    }
  });
});