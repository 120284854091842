define('ui/initializers/global-store', ['exports', 'ember-api-store/initializers/store'], function (exports, _store) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'global-store',
    initialize: (0, _store.default)('globalStore')
  };
});