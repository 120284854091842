define('ui/models/volume', ['exports', 'ember-api-store/models/resource'], function (exports, _resource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.registerSource = registerSource;
  exports.getSources = getSources;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var SOURCES = [];

  //             name/component    field                     component ephemeral persistent driver
  registerSource('aws-ebs', 'awsElasticBlockStore', true, true, true);
  registerSource('azure-disk', 'azureDisk', true, true, true);
  registerSource('azure-file', 'azureFile', true, true, true);
  registerSource('cephfs', 'cephfs', true, true, true);
  registerSource('cinder', 'cinder', true, true, true);
  registerSource('config-map', 'configMap', true, false, false);
  // registerSource('downward-api',   'downwardAPI',            true,     true,     false);
  registerSource('empty-dir', 'emptyDir', true, true, false);
  registerSource('fc', 'fc', true, true, true);
  registerSource('flex-volume', 'flexVolume', true, true, true);
  registerSource('flex-volume-longhorn', 'flexVolume', true, true, true, 'rancher.io/longhorn');
  registerSource('flocker', 'flocker', true, true, true);
  registerSource('gce-pd', 'gcePersistentDisk', true, true, true);
  // registerSource('git-repo',       'gitRepo',                true,     true,     false);
  registerSource('glusterfs', 'glusterfs', true, true, true);
  registerSource('host-path', 'hostPath', true, true, true);
  registerSource('iscsi', 'iscsi', true, true, true);
  registerSource('local', 'local', true, false, true);
  registerSource('nfs', 'nfs', true, true, true);
  // registerSource('pvc',            'persisitentVolumeClaim', true,     true,     false);
  registerSource('photon', 'photonPersistentDisk', true, true, true);
  registerSource('portworx', 'portworxVolume', true, true, true);
  // registerSource('projected',      'projected',              true,     true,     false);
  registerSource('quobyte', 'quobyte', true, true, true);
  registerSource('rbd', 'rbd', true, true, true);
  registerSource('scaleio', 'scaleIO', true, true, true);
  registerSource('secret', 'secret', true, true, false);
  registerSource('storageos', 'storageos', true, true, true);
  registerSource('vsphere-volume', 'vsphereVolume', true, true, true);

  function registerSource(name, field, component) {
    var ephemeral = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
    var persistent = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
    var driver = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : '';

    if (component === true) {
      component = name;
    }

    var existing = SOURCES.findBy('name', name);

    if (existing) {
      SOURCES.removeObject(existing);
    }

    SOURCES.push({
      name: name,
      value: field,
      driver: driver,
      component: component,
      ephemeral: !!ephemeral,
      persistent: !!persistent
    });
  }

  function getSources() {
    var which = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'all';

    if (which === 'ephemeral') {
      return JSON.parse(JSON.stringify(SOURCES.filter(function (s) {
        return s.ephemeral;
      })));
    } else if (which === 'persistent') {
      return JSON.parse(JSON.stringify(SOURCES.filter(function (s) {
        return s.persistent;
      })));
    } else {
      return JSON.parse(JSON.stringify(SOURCES));
    }
  }

  var Volume = _resource.default.extend({
    intl: Ember.inject.service(),
    reservedKeys: ['configName'],

    type: 'volume',

    init: function init() {
      this._super.apply(this, arguments);

      var keys = SOURCES.map(function (x) {
        return x.value;
      });

      Ember.set(this, 'configName', Ember.computed.call.apply(Ember.computed, [this].concat(_toConsumableArray(keys), [function () {
        for (var key, i = 0; i < keys.length; i++) {
          key = keys[i];
          if (Ember.get(this, key)) {
            return key;
          }
        }

        return null;
      }])));
    },


    config: Ember.computed('configName', function () {
      var key = Ember.get(this, 'configName');

      if (key) {
        return Ember.get(this, key);
      }
    }),
    sourceName: Ember.computed('configName', function () {
      var key = Ember.get(this, 'configName');

      if (!key) {
        return;
      }
      var entry = void 0;
      var driver = Ember.get(this, key).driver;

      entry = SOURCES.findBy('value', key);
      if (key === 'flexVolume' && driver) {
        var specialSource = SOURCES.findBy('driver', driver);

        if (specialSource) {
          entry = specialSource;
        }
      }
      if (entry) {
        return entry.name;
      }
    }),
    displaySource: Ember.computed('sourceName', 'intl.locale', function () {
      var intl = Ember.get(this, 'intl');
      var sourceName = Ember.get(this, 'sourceName');

      if (sourceName) {
        return intl.t('volumeSource.' + sourceName + '.title');
      }
    }),

    clearSourcesExcept: function clearSourcesExcept(keep) {
      var keys = SOURCES.map(function (x) {
        return x.value;
      });

      for (var key, i = 0; i < keys.length; i++) {
        key = keys[i];
        if (key !== keep && Ember.get(this, key)) {
          Ember.set(this, key, null);
        }
      }
    }
  });

  Volume.reopenClass({
    stateMap: {
      'active': {
        icon: 'icon icon-hdd',
        color: 'text-success'
      }
    }
  });

  exports.default = Volume;
});