define('ui/components/system-info-section/component', ['exports', 'ui/components/system-info-section/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    node: null,

    sortBy: 'key',
    descending: false,
    labelArray: null,

    headers: [{
      name: 'key',
      sort: ['key'],
      translationKey: 'systemInfoSection.key'
    }, {
      name: 'value',
      sort: ['value', 'key'],
      translationKey: 'systemInfoSection.value'
    }],

    didReceiveAttrs: function didReceiveAttrs() {
      var labelArray = this.getSystemInfoArray();

      Ember.set(this, 'labelArray', labelArray);
    },


    annotationsObserver: Ember.observer('node', function () {
      var labelArray = this.getSystemInfoArray();

      Ember.set(this, 'labelArray', labelArray);
    }),

    getSystemInfoArray: function getSystemInfoArray() {
      var array = [];
      var node = Ember.get(this, 'node');
      var info = Ember.get(node, 'info');
      var kubernetes = Ember.get(info, 'kubernetes');
      var os = Ember.get(info, 'os');
      var arch = (Ember.get(node, 'labels') || {})['beta.kubernetes.io/arch'];
      var osType = (Ember.get(node, 'labels') || {})['beta.kubernetes.io/os'];

      if (kubernetes) {
        array.push({
          key: 'systemInfoSection.kubeProxyVersion',
          value: Ember.get(kubernetes, 'kubeProxyVersion')
        });
        array.push({
          key: 'systemInfoSection.kubeletVersion',
          value: Ember.get(kubernetes, 'kubeletVersion')
        });
      }

      if (os) {
        array.push({
          key: 'systemInfoSection.dockerVersion',
          value: Ember.get(os, 'dockerVersion')
        });
        array.push({
          key: 'systemInfoSection.kernelVersion',
          value: Ember.get(os, 'kernelVersion')
        });
        array.push({
          key: 'systemInfoSection.operatingSystem',
          value: Ember.get(os, 'operatingSystem')
        });
      }

      if (arch) {
        array.push({
          key: 'systemInfoSection.arch',
          value: arch
        });
      }
      if (osType) {
        array.push({
          key: 'systemInfoSection.osType',
          value: osType
        });
      }

      return array;
    }
  });
});