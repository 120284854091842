define('ui/models/clusteralert', ['exports', 'ember-api-store/models/resource', 'ui/mixins/model-alert'], function (exports, _resource, _modelAlert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ClusterAlert = _resource.default.extend(_modelAlert.default, {
    intl: Ember.inject.service(),
    type: 'clusteralert',

    _targetType: 'systemService',

    canClone: true,

    init: function init() {
      this._super.apply(this, arguments);
    },


    targetType: function () {
      var targetSystemService = Ember.get(this, 'targetSystemService');
      var targetNode = Ember.get(this, 'targetNode');
      var targetEvent = Ember.get(this, 'targetEvent');

      if (targetSystemService && targetSystemService.condition) {
        return 'systemService';
      }
      if (targetNode && targetNode.nodeId) {
        return 'node';
      }
      if (targetNode && targetNode.selector) {
        return 'nodeSelector';
      }
      if (targetEvent && targetEvent.resourceKind) {
        return 'event';
      }
    }.property('targetSystemService.{condition}', 'targetNode{nodeId,selector}', 'targetEvent.{resourceKind}'),

    displayTargetType: function () {
      var t = Ember.get(this, 'targetType');
      var intl = Ember.get(this, 'intl');

      return intl.t('alertPage.targetTypes.' + t);
    }.property('targetType'),

    displayCondition: function () {
      var t = Ember.get(this, 'targetType');
      var intl = Ember.get(this, 'intl');

      if (t === 'systemService') {
        return intl.t('alertPage.index.table.displayCondition.unhealthy');
      }
      if (t === 'event') {
        return intl.t('alertPage.index.table.displayCondition.happens');
      }
      if (t === 'node' || t === 'nodeSelector') {
        var c = Ember.get(this, 'targetNode.condition');

        if (c === 'notready') {
          return intl.t('alertPage.index.table.displayCondition.notReady');
        }
        if (c === 'cpu') {
          var n = Ember.get(this, 'targetNode.cpuThreshold');

          return intl.t('alertPage.index.table.displayCondition.cpuUsage', { percent: n });
        }
        if (c === 'mem') {
          var _n = Ember.get(this, 'targetNode.memThreshold');

          return intl.t('alertPage.index.table.displayCondition.memUsage', { percent: _n });
        }
      }

      return intl.t('alertPage.na');
    }.property('targetType', 'targetNode.{condition,cpuThreshold,memThreshold}'),

    threshold: function () {
      var t = Ember.get(this, 'targetType');
      var c = Ember.get(this, 'targetNode.condition');

      if (t === 'node' || t === 'nodeSelector') {
        if (c === 'cpu') {
          return Ember.get(this, 'targetNode.cpuThreshold');
        }
        if (c === 'mem') {
          return Ember.get(this, 'targetNode.memThreshold');
        }
      }

      return null;
    }.property('targetType', 'targetNode.{memThreshold,cpuThreshold,condition}'),

    actions: {
      clone: function clone() {
        Ember.get(this, 'router').transitionTo('authenticated.cluster.alert.new', { queryParams: { id: Ember.get(this, 'id') } });
      }
    }

  });

  exports.default = ClusterAlert;
});