define('ui/models/container', ['exports', 'ember-api-store/models/resource', 'ember-api-store/utils/denormalize', 'shared/mixins/display-image'], function (exports, _resource, _denormalize, _displayImage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Container = _resource.default.extend(_displayImage.default, {
    modalService: Ember.inject.service('modal'),
    intl: Ember.inject.service(),
    scope: Ember.inject.service(),
    router: Ember.inject.service(),

    links: {},
    type: 'container',

    pod: (0, _denormalize.reference)('podId'),

    availableActions: Ember.computed('state', function () {
      var isRunning = Ember.get(this, 'state') === 'running';

      var choices = [{
        label: 'action.execute',
        icon: 'icon icon-terminal',
        action: 'shell',
        enabled: isRunning,
        altAction: 'popoutShell'
      }, {
        label: 'action.logs',
        icon: 'icon icon-file',
        action: 'logs',
        enabled: true,
        altAction: 'popoutLogs'
      }];

      return choices;
    }),

    validateQuota: function validateQuota() {
      var projectLimit = Ember.get(this, 'scope.currentProject.resourceQuota.limit');

      if (!projectLimit) {
        return [];
      }

      var intl = Ember.get(this, 'intl');
      var errors = [];

      var limitsCpu = projectLimit.limitsCpu,
          limitsMemory = projectLimit.limitsMemory,
          requestsCpu = projectLimit.requestsCpu,
          requestsMemory = projectLimit.requestsMemory;


      if (limitsCpu && !Ember.get(this, 'resources.limits.cpu')) {
        errors.push(intl.t('newContainer.errors.quotaRequired', { key: intl.t('formResourceQuota.resources.limitsCpu') }));
      }
      if (limitsMemory && !Ember.get(this, 'resources.limits.memory')) {
        errors.push(intl.t('newContainer.errors.quotaRequired', { key: intl.t('formResourceQuota.resources.limitsMemory') }));
      }
      if (requestsCpu && !Ember.get(this, 'resources.requests.cpu')) {
        errors.push(intl.t('newContainer.errors.quotaRequired', { key: intl.t('formResourceQuota.resources.requestsCpu') }));
      }
      if (requestsMemory && !Ember.get(this, 'resources.requests.memory')) {
        errors.push(intl.t('newContainer.errors.quotaRequired', { key: intl.t('formResourceQuota.resources.requestsMemory') }));
      }

      return errors;
    },


    actions: {
      shell: function shell() {
        Ember.get(this, 'modalService').toggleModal('modal-shell', {
          model: Ember.get(this, 'pod'),
          containerName: Ember.get(this, 'name')
        });
      },
      popoutShell: function popoutShell() {
        var _this = this;

        var projectId = Ember.get(this, 'scope.currentProject.id');
        var podId = Ember.get(this, 'pod.id');
        var route = Ember.get(this, 'router').urlFor('authenticated.project.console', projectId);

        var system = Ember.get(this, 'pod.node.info.os.operatingSystem') || '';
        var windows = false;

        if (system.startsWith('Windows')) {
          windows = true;
        }

        Ember.run.later(function () {
          window.open('//' + window.location.host + route + '?podId=' + podId + '&windows=' + windows + '&containerName=' + Ember.get(_this, 'name') + '&isPopup=true', '_blank', 'toolbars=0,width=900,height=700,left=200,top=200');
        });
      },
      logs: function logs() {
        Ember.get(this, 'modalService').toggleModal('modal-container-logs', {
          model: Ember.get(this, 'pod'),
          containerName: Ember.get(this, 'name')
        });
      },
      popoutLogs: function popoutLogs() {
        var _this2 = this;

        var projectId = Ember.get(this, 'scope.currentProject.id');
        var podId = Ember.get(this, 'pod.id');
        var route = Ember.get(this, 'router').urlFor('authenticated.project.container-log', projectId);

        Ember.run.later(function () {
          window.open('//' + window.location.host + route + '?podId=' + podId + '&containerName=' + Ember.get(_this2, 'name') + '&isPopup=true', '_blank', 'toolbars=0,width=900,height=700,left=200,top=200');
        });
      }
    }

  });

  exports.default = Container;
});