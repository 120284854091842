define('ui/components/marked-down/component', ['exports', 'shared/components/marked-down/component', 'npm:marked'], function (exports, _component, _npmMarked) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _component.default;
    }
  });
});