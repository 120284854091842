define('ui/authenticated/project/security/members/new/controller', ['exports', 'ui/mixins/new-or-edit'], function (exports, _newOrEdit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ROLE_KINDS = [{ id: 'User' }, { id: 'Group' }];

  exports.default = Ember.Controller.extend(_newOrEdit.default, {
    primaryResource: null,
    kinds: ROLE_KINDS,
    actions: {
      doneSaving: function doneSaving() {
        this.transitionToRoute('authenticated.project.security.members.index', Ember.get(this, 'model.project.id'));
      }
    },
    filteredUsers: Ember.computed('model.users.@each.{id,state}', function () {
      return Ember.get(this, 'model.users').filter(function (u) {
        return !u.hasOwnProperty('me') || Ember.get(u, 'me') === false;
      }).sortBy('username');
    })
  });
});