define('ui/components/cru-persistent-volume/component', ['exports', 'shared/mixins/view-new-edit', 'ui/components/cru-persistent-volume/template', 'ui/models/volume', 'shared/utils/parse-unit'], function (exports, _viewNewEdit, _template, _volume, _parseUnit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_viewNewEdit.default, {
    intl: Ember.inject.service(),
    clusterStore: Ember.inject.service(),

    layout: _template.default,
    model: null,
    sourceName: null,

    titleKey: 'cruPersistentVolume.title',

    storageClasses: null,
    capacity: null,

    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'storageClasses', Ember.get(this, 'clusterStore').all('storageclass'));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (Ember.get(this, 'isNew')) {
        Ember.set(this, 'capacity', 10);
      } else {
        Ember.set(this, 'sourceName', Ember.get(this, 'primaryResource.sourceName'));

        var capacity = Ember.get(this, 'primaryResource.capacity.storage');

        if (capacity) {
          var bytes = (0, _parseUnit.parseSi)(capacity);
          var gib = bytes / Math.pow(1024, 3);

          Ember.set(this, 'capacity', gib);
        }
      }
    },


    actions: {
      updateOptions: function updateOptions(ary) {
        Ember.set(this, 'primaryResource.mountOptions', ary);
      },
      updateNodeAffinities: function updateNodeAffinities(nodeSelector) {
        if (nodeSelector.nodeSelectorTerms.length > 0) {
          Ember.set(this, 'primaryResource.nodeAffinity', { required: nodeSelector });
        } else {
          Ember.set(this, 'primaryResource.nodeAffinity', null);
        }
      }
    },

    sourceChoices: Ember.computed('intl.locale', function () {
      var intl = Ember.get(this, 'intl');
      var out = (0, _volume.getSources)('persistent').map(function (p) {
        var entry = Object.assign({}, p);
        var key = 'volumeSource.' + entry.name + '.title';

        if (intl.exists(key)) {
          entry.label = intl.t(key);
          entry.priority = 1;
        } else {
          entry.label = entry.name;
          entry.priority = 2;
        }

        return entry;
      });

      return out.sortBy('priority', 'label');
    }),

    sourceComponent: Ember.computed('sourceName', function () {
      var name = Ember.get(this, 'sourceName');
      var sources = (0, _volume.getSources)('persistent');
      var entry = sources.findBy('name', name);

      if (entry) {
        return 'volume-source/source-' + name;
      }
    }),

    willSave: function willSave() {
      var vol = Ember.get(this, 'primaryResource');

      var entry = (0, _volume.getSources)('persistent').findBy('name', Ember.get(this, 'sourceName'));

      if (!entry) {
        var errors = [];
        var intl = Ember.get(this, 'intl');

        errors.push(intl.t('validation.required', { key: intl.t('cruPersistentVolume.source.label') }));
        Ember.set(this, 'errors', errors);

        return false;
      }

      vol.clearSourcesExcept(entry.value);

      var capacity = Ember.get(this, 'capacity');

      if (capacity) {
        Ember.set(vol, 'capacity', { storage: capacity + 'Gi' });
      } else {
        var _errors = [];
        var _intl = Ember.get(this, 'intl');

        _errors.push(_intl.t('validation.required', { key: _intl.t('cruPersistentVolumeClaim.capacity.label') }));
        Ember.set(this, 'errors', _errors);

        return false;
      }

      return this._super.apply(this, arguments);
    },
    doneSaving: function doneSaving() {
      this.sendAction('cancel');
    }
  });
});