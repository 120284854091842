define('ui/initializers/webhook-store', ['exports', 'ember-api-store/initializers/store'], function (exports, _store) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'webhook-store',
    initialize: (0, _store.default)('webhook-store', 'webhookStore')
  };
});