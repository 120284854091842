define('ui/models/openldapconfig', ['exports', 'ember-api-store/models/resource'], function (exports, _resource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OpenLdapConfig = _resource.default.extend({ type: 'openLdapConfig' });

  exports.default = OpenLdapConfig;
});