define('ui/models/namespacedsecret', ['exports', 'ui/models/secret', 'ember-api-store/utils/denormalize'], function (exports, _secret, _denormalize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _secret.default.extend({
    clusterStore: Ember.inject.service(),
    router: Ember.inject.service(),

    canClone: true,

    namespace: (0, _denormalize.reference)('namespaceId', 'namespace', 'clusterStore'),

    actions: {
      edit: function edit() {
        Ember.get(this, 'router').transitionTo('authenticated.project.secrets.detail.edit', Ember.get(this, 'id'));
      },
      clone: function clone() {
        Ember.get(this, 'router').transitionTo('authenticated.project.secrets.new', {
          queryParams: {
            id: Ember.get(this, 'id'),
            type: Ember.get(this, 'type')
          }
        });
      }
    }
  });
});