define('ui/components/taints-section/component', ['exports', 'shared/mixins/manage-labels', 'ui/components/taints-section/template'], function (exports, _manageLabels, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_manageLabels.default, {
    layout: _template.default,

    sortBy: 'key',
    descending: false,

    headers: [{
      name: 'key',
      sort: ['key'],
      translationKey: 'taintsSection.key',
      width: 200
    }, {
      name: 'value',
      sort: ['value'],
      translationKey: 'taintsSection.value',
      width: 200
    }, {
      name: 'effect',
      sort: ['effect'],
      translationKey: 'taintsSection.effect',
      width: 200
    }]
  });
});