define('ui/components/node-row/component', ['exports', 'ui/components/node-row/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.headersGlobal = exports.headersCluster = exports.headersAll = undefined;
  var headersAll = exports.headersAll = [{
    name: 'state',
    sort: ['sortState', 'displayName'],
    searchField: 'displayState',
    translationKey: 'generic.state',
    width: 120
  }, {
    name: 'name',
    sort: ['displayName', 'id'],
    searchField: 'displayName',
    translationKey: 'generic.name'
  }, {
    name: 'cluster',
    sort: ['cluster.displayName', 'name', 'id'],
    searchField: 'cluster.displayName',
    translationKey: 'nodesPage.table.clusterName',
    views: ['global']
  }, {
    name: 'roles',
    sort: ['sortRole', 'name', 'id'],
    searchField: 'displayRoles',
    translationKey: 'nodesPage.table.role',
    views: ['cluster'],
    width: 120
  }, {
    name: 'version',
    sort: ['name', 'id'],
    searchField: null,
    translationKey: 'nodesPage.table.version',
    views: ['cluster'],
    width: 120
  }, {
    name: 'cpu',
    sort: ['cpuUsage', 'displayName'],
    searchField: null,
    width: 100,
    translationKey: 'nodesPage.table.cpu',
    classNames: ['text-right']
  }, {
    name: 'memory',
    sort: ['memoryUsage', 'displayName'],
    searchField: null,
    width: 150,
    translationKey: 'nodesPage.table.memory',
    classNames: ['text-right']
  }, {
    name: 'pod',
    sort: ['podUsage', 'displayName'],
    searchField: null,
    width: 100,
    translationKey: 'nodesPage.table.pod',
    classNames: ['text-right']
  }];

  var headersMap = {
    all: headersAll,
    global: headersAll.filter(function (x) {
      return !x.views || x.views.includes('global');
    }),
    cluster: headersAll.filter(function (x) {
      return !x.views || x.views.includes('cluster');
    })
  };

  var headersCluster = exports.headersCluster = headersMap.cluster;
  var headersGlobal = exports.headersGlobal = headersMap.global;

  exports.default = Ember.Component.extend({
    scope: Ember.inject.service(),
    session: Ember.inject.service(),

    layout: _template.default,
    view: 'project',
    model: null,
    tagName: '',
    subMatches: null,
    expanded: null,

    showLabelRow: Ember.computed.or('model.displayUserLabelStrings.length', 'model.requireAnyLabelStrings.length'),

    actions: {
      toggle: function toggle() {
        this.sendAction('toggle');
      }
    },

    showCluster: Ember.computed('view', function () {
      return !!headersMap[Ember.get(this, 'view')].findBy('name', 'cluster');
    }),

    showRoles: Ember.computed('view', function () {
      return !!headersMap[Ember.get(this, 'view')].findBy('name', 'roles');
    }),

    labelColspan: Ember.computed('fullColspan', function () {
      return Ember.get(this, 'fullColspan') + 1;
    })
  });
});