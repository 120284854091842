define('ui/components/container-shell/component', ['exports', 'shared/components/container-shell/component', 'npm:xterm'], function (exports, _component, _npmXterm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _component.default;
    }
  });
});