define('ui/components/input-custom-answers/component', ['exports', 'ui/utils/platform', 'npm:json2yaml', 'npm:dot-object', 'ui/components/input-custom-answers/template'], function (exports, _platform, _npmJson2yaml, _npmDotObject, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function convertKey(key) {
    var out = '';
    var splits = key.split('.');

    splits.forEach(function (split, index) {
      if (/^\d+$/.test(split)) {
        out += '[' + split + ']';
      } else {
        out += index === 0 ? split : '.' + split;
      }
    });

    return out;
  }

  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    growl: Ember.inject.service(),

    layout: _template.default,
    pasteOrUpload: false,
    showInput: true,
    accept: '.yml, .yaml',
    app: null,

    actions: {
      upload: function upload() {
        this.$('INPUT[type=file]')[0].click();
      },
      showPaste: function showPaste() {
        Ember.set(this, 'pasteOrUpload', true);
      },
      cancel: function cancel() {
        Ember.set(this, 'pasteOrUpload', false);
      }
    },

    actualAccept: Ember.computed('accept', function () {
      if (_platform.isSafari) {
        return '';
      } else {
        return Ember.get(this, 'accept');
      }
    }),

    pastedAnswers: Ember.computed('pasteOrUpload', {
      get: function get() {
        var input = Ember.get(this, 'app.answers');
        var obj = {};

        Object.keys(input).forEach(function (key) {
          var value = input[key];

          key = key.replace(/\]\[/g, '.').replace(/\]\./g, '.').replace(/\[/g, '.');

          if (key.startsWith('.')) {
            key = key.substr(1, key.length);
          }

          if (key.endsWith(']')) {
            key = key.substr(0, key.length - 1);
          }
          _npmDotObject.default.str(key, value, obj);
        });

        return Object.keys(obj).length > 0 ? _npmJson2yaml.default.stringify(obj) : '# ' + Ember.get(this, 'intl').t('inputAnswers.yamlProtip') + '\n';
      },
      set: function set(key, value) {
        var _this = this;

        var out = {};
        var json = void 0;

        try {
          json = YAML.parse(value);
        } catch (err) {
          Ember.set(this, 'yamlErrors', ['YAML Parse Error: ' + err.snippet + ' - ' + err.message]);

          return value;
        }

        Ember.set(this, 'yamlErrors', []);

        if (json && (typeof json === 'undefined' ? 'undefined' : _typeof(json)) === 'object') {
          var dot = {};

          _npmDotObject.default.dot(json, dot);
          Object.keys(dot).forEach(function (key) {
            var value = _typeof(dot[key]) === 'object' ? JSON.stringify(dot[key]) : dot[key];

            key = convertKey(key);

            out[key] = value;
          });
        } else {
          return value;
        }

        Ember.set(this, 'showInput', false);
        Ember.set(this, 'app.answers', out);

        Ember.run.next(function () {
          Ember.set(_this, 'showInput', true);
        });

        return value;
      }
    }),

    change: function change(event) {
      var _this2 = this;

      if (Ember.get(this, 'pasteOrUpload')) {
        return;
      }

      var input = event.target;

      if (input.files && input.files[0]) {
        var file = input.files[0];

        var reader = new FileReader();

        reader.onerror = function (err) {
          Ember.get(_this2, 'growl').fromError(Ember.get(err, 'srcElement.error.message'));
        };

        reader.onload = function (event2) {
          var out = event2.target.result;

          Ember.set(_this2, 'pastedAnswers', out);
          input.value = '';
        };
        reader.readAsText(file);
      }
    }
  });
});