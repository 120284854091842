define('ui/models/scalehost', ['exports', 'ember-api-store/models/resource'], function (exports, _resource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _resource.default.extend({
    hostSelectorStr: function () {
      var all = this.get('hostSelector') || [];

      return Object.keys(all).map(function (key) {
        var val = all[key];

        return key + (val ? '=' + val : '');
      }).join(', ');
    }.property('hostSelector'),

    validationErrors: function validationErrors() {
      var errors = this._super.apply(this, arguments);
      var min = parseInt(this.get('min'), 10);
      var max = parseInt(this.get('max'), 10);

      if (min && max && min > max) {
        errors.push('"Minimum Scale" cannot be greater than "Maximum Scale"');
      }

      return errors;
    }
  });
});