define('ui/components/form-sources-row/component', ['exports', 'ui/components/form-sources-row/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SOURCES = [{
    id: 'configMap',
    label: 'Config Map',
    disabled: false
  }, {
    id: 'field',
    label: 'Field',
    disabled: true
  }, {
    id: 'resource',
    label: 'Resource',
    disabled: true
  }, {
    id: 'secret',
    label: 'Secret',
    disabled: false
  }];

  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'tr',
    source: null,
    editing: true,
    secretOnly: false,
    specificKeyOnly: false,

    selectedSecret: null,
    sources: SOURCES,

    prefixOrTarget: Ember.computed('source.sourceKey', {
      get: function get() {
        if (Ember.get(this, 'source.sourceKey') === null || Ember.get(this, 'source.sourceKey') === undefined) {
          return Ember.get(this, 'source.prefix');
        } else {
          return Ember.get(this, 'source.targetKey');
        }
      },
      set: function set(key, value) {
        if (Ember.get(this, 'source.sourceKey') === null || Ember.get(this, 'source.sourceKey') === undefined) {
          return Ember.set(this, 'source.prefix', value);
        } else {
          return Ember.set(this, 'source.targetKey', value);
        }
      }
    }),

    prefixOrKeys: Ember.computed('source.sourceName', 'selectedSecret', 'selectedConfigMap', function () {
      var prefix = {
        id: null,
        label: 'All'
      };
      var sourceType = Ember.get(this, 'source.source');
      var sourceName = Ember.get(this, 'source.sourceName');
      var out = Ember.get(this, 'specificKeyOnly') ? [] : [prefix];
      var selected = void 0;

      switch (sourceType) {
        case 'secret':
          selected = Ember.get(this, 'selectedSecret');
          break;
        case 'configMap':
          selected = Ember.get(this, 'selectedConfigMap');
          break;
      }

      if (sourceName) {
        if (selected && Ember.get(selected, 'data')) {
          var keys = Object.keys(Ember.get(selected, 'data'));

          if (keys) {
            keys.forEach(function (sk) {
              out.addObject({
                id: sk,
                label: sk
              });
            });
          }
        }
      }

      return out;
    })

  });
});