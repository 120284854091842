define('ui/models/googlekubernetesengineconfig', ['exports', 'ember-api-store/models/resource'], function (exports, _resource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _resource.default.extend({
    type: 'googleKubernetesEngineConfig',

    reservedKeys: [],

    validationErrors: function validationErrors() {
      var errors = [];

      if (!this.get('credential')) {
        errors.push('"Service Account" is required');
      } else if (!this.get('projectId')) {
        errors.push('"Google Project ID" is required');
      }
      if (errors.length > 0) {
        return errors;
      }
      errors = this._super.apply(this, arguments);

      return errors;
    }
  });
});