define('ui/update-password/route', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    access: Ember.inject.service(),
    settings: Ember.inject.service(),

    model: function model() {
      var _this = this;

      var promise = void 0;

      if (Ember.get(this, 'access.firstLogin')) {
        promise = Ember.get(this, 'settings').load([_constants.default.SETTING.VERSION_RANCHER, _constants.default.SETTING.TELEMETRY]);
      } else {
        promise = Ember.RSVP.resolve();
      }

      return promise.then(function () {
        var cur = Ember.get(_this, 'settings.' + _constants.default.SETTING.TELEMETRY);
        var version = Ember.get(_this, 'settings.' + _constants.default.SETTING.VERSION_RANCHER);
        var optIn = void 0;

        if (version && !['dev', 'master'].includes(version)) {
          // For releases, default to opt in
          optIn = cur !== 'out';
        } else {
          // For master, default to opt out
          optIn = cur === 'in';
        }

        return {
          user: Ember.get(_this, 'access.me'),
          code: Ember.get(_this, 'access.userCode') || '',
          optIn: optIn
        };
      });
    },
    activate: function activate() {
      $('BODY').addClass('container-farm'); // eslint-disable-line
    },
    deactivate: function deactivate() {
      $('BODY').removeClass('container-farm'); // eslint-disable-line
    }
  });
});