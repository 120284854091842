define('ui/components/node-conditions/component', ['exports', 'ui/components/node-conditions/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var FALSE = 'False';
  var TRUE = 'True';

  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    layout: _template.default,

    conditionsSource: null,

    conditions: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.setConditions();
    },


    updateConditions: Ember.observer('conditionsSource.@each.{status,reason,message}', function () {
      this.setConditions();
    }),
    setConditions: function setConditions() {
      var conditions = Ember.get(this, 'conditionsSource');

      var outOfDisk = conditions.find(function (c) {
        return c.type === 'OutOfDisk';
      });
      var diskPressure = conditions.find(function (c) {
        return c.type === 'DiskPressure';
      });
      var memoryPressure = conditions.find(function (c) {
        return c.type === 'MemoryPressure';
      });
      var ready = conditions.find(function (c) {
        return c.type === 'Ready';
      });

      this.set('conditions', [{
        name: Ember.get(this, 'intl').t('hostsPage.hostPage.conditions.diskSpace'),
        healthy: !outOfDisk || Ember.get(outOfDisk, 'status') === FALSE
      }, {
        name: Ember.get(this, 'intl').t('hostsPage.hostPage.conditions.diskPressure'),
        healthy: !diskPressure || Ember.get(diskPressure, 'status') === FALSE
      }, {
        name: Ember.get(this, 'intl').t('hostsPage.hostPage.conditions.memoryPressure'),
        healthy: !memoryPressure || Ember.get(memoryPressure, 'status') === FALSE
      }, {
        name: Ember.get(this, 'intl').t('hostsPage.hostPage.conditions.ready'),
        healthy: ready && Ember.get(ready, 'status') === TRUE
      }]);
    }
  });
});