define('ui/models/roletemplate', ['exports', 'ember-api-store/models/resource', 'ui/utils/constants'], function (exports, _resource, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _resource.default.extend({
    router: Ember.inject.service(),

    type: 'roleTemplate',
    canClone: true,

    state: Ember.computed('locked', function () {
      return Ember.get(this, 'locked') ? 'locked' : 'active';
    }),

    isCustom: Ember.computed('roleTemplateId', function () {
      return !_constants.default.BASIC_ROLE_TEMPLATE_ROLES.includes(Ember.get(this, 'id'));
    }),

    displayName: Ember.computed('name', 'id', function () {
      var name = Ember.get(this, 'name');

      if (name) {
        return name;
      }

      return '(' + Ember.get(this, 'id') + ')';
    }),

    canRemove: Ember.computed('links.remove', 'builtin', function () {
      return !!Ember.get(this, 'links.remove') && !Ember.get(this, 'builtin');
    }),
    actions: {
      edit: function edit() {
        Ember.get(this, 'router').transitionTo('global-admin.security.roles.edit', Ember.get(this, 'id'));
      },
      clone: function clone() {
        Ember.get(this, 'router').transitionTo('global-admin.security.roles.new', { queryParams: { id: Ember.get(this, 'id') } });
      }
    }

  });
});