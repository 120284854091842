define('ui/components/cru-certificate/component', ['exports', 'shared/mixins/view-new-edit', 'shared/mixins/optionally-namespaced', 'ui/components/cru-certificate/template'], function (exports, _viewNewEdit, _optionallyNamespaced, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var BEGIN_CERTIFICATE = ['-----BEGIN CERTIFICATE-----'];

  var BEGIN_KEY = ['-----BEGIN PRIVATE KEY-----', '-----BEGIN EC PRIVATE KEY-----', '-----BEGIN RSA PRIVATE KEY-----'];

  exports.default = Ember.Component.extend(_viewNewEdit.default, _optionallyNamespaced.default, {
    intl: Ember.inject.service(),

    layout: _template.default,
    model: null,
    titleKey: 'newCertificate.title',
    scope: 'project',
    namespace: null,

    projectType: 'certificate',
    namespacedType: 'namespacedCertificate',

    isEncrypted: Ember.computed('model.key', function () {
      var key = Ember.get(this, 'model.key') || '';

      return key.match(/^Proc-Type: 4,ENCRYPTED$/m) || key.match(/^-----BEGIN ENCRYPTED.* KEY-----$/m);
    }),

    validate: function validate() {
      this._super();

      var errors = Ember.get(this, 'errors') || [];

      if (Ember.get(this, 'scope') !== 'project') {
        errors.pushObjects(Ember.get(this, 'namespaceErrors') || []);
      }

      var intl = Ember.get(this, 'intl');

      if (Ember.get(this, 'isEncrypted')) {
        errors.push(intl.t('newCertificate.errors.encrypted'));
      }

      var key = Ember.get(this, 'model.key');

      if (key) {
        var ok = false;

        BEGIN_KEY.forEach(function (prefix) {
          if (key.trim().startsWith(prefix)) {
            ok = true;
          }
        });

        if (!ok) {
          errors.push(intl.t('newCertificate.errors.key.invalidFormat'));
        }
      } else {
        errors.push(intl.t('newCertificate.errors.key.required'));
      }

      var certs = Ember.get(this, 'model.certs');

      if (certs) {
        var _ok = false;

        BEGIN_CERTIFICATE.forEach(function (prefix) {
          if (certs.trim().startsWith(prefix)) {
            _ok = true;
          }
        });

        if (!_ok) {
          errors.push(intl.t('newCertificate.errors.cert.invalidFormat'));
        }
      } else {
        errors.push(intl.t('newCertificate.errors.cert.required'));
      }

      Ember.set(this, 'errors', errors);

      return Ember.get(this, 'errors.length') === 0;
    }
  });
});