define('ui/components/container/form-container-links/component', ['exports', 'ui/mixins/container-choices', 'shared/components/accordion-list-item/component', 'ui/components/container/form-container-links/template'], function (exports, _containerChoices, _component, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var headers = [{
    name: 'name',
    translationKey: 'formContainerLinks.name.label'
  }, {
    name: 'alias',
    translationKey: 'formContainerLinks.alias.label'
  }];

  exports.default = Ember.Component.extend(_containerChoices.default, {
    router: Ember.inject.service(),
    growl: Ember.inject.service(),

    layout: _template.default,
    // Inputs
    editing: null,
    instance: null,

    tagName: '',
    errors: null,

    headers: headers,

    statusClass: null,
    linksArray: Ember.computed.alias('instance.instanceLinks'),

    actions: {
      addLink: function addLink() {
        var links = this.get('linksArray');

        if (!links) {
          links = [];
          this.set('linksArray', links);
        }

        links.pushObject(this.get('store').createRecord({
          type: 'link',
          name: '',
          alias: ''
        }));
      },
      removeLink: function removeLink(obj) {
        this.get('linksArray').removeObject(obj);
      },
      followLink: function followLink(str) {
        var stack = void 0,
            stackName = void 0,
            containerName = void 0;

        if (str.includes('/')) {
          var _name$split = name.split('/');

          var _name$split2 = _slicedToArray(_name$split, 2);

          stackName = _name$split2[0];
          containerName = _name$split2[1];

          var stacks = this.get('store').all('stack');

          stack = stacks.findBy('name', stackName);
        } else {
          stack = this.get('stack');
          containerName = str;
        }

        if (stack) {
          var container = stack.get('instances').findBy('name', containerName);

          if (container) {
            this.get('router').transitionTo('container', container.get('id'));

            return;
          }
        }

        this.get('growl').fromError('Unable to find container for "' + name + '"');
      }
    },

    status: function () {
      var k = _component.STATUS.NONE;
      var count = (this.get('linksArray') || []).filterBy('name').get('length') || 0;

      if (count) {
        if (this.get('errors.length')) {
          k = _component.STATUS.INCOMPLETE;
        } else {
          k = _component.STATUS.COUNTCONFIGURED;
        }
      }

      this.set('statusClass', (0, _component.classForStatus)(k));

      return this.get('intl').t(_component.STATUS_INTL_KEY + '.' + k, { count: count });
    }.property('linksArray.@each.name')
  });
});