define('ui/components/catalog-box/component', ['exports', 'ui/components/catalog-box/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    settings: Ember.inject.service(),
    layout: _template.default,
    tagName: 'div',

    classNames: ['catalog-box', 'p-0'],
    classNameBindings: ['active::inactive', 'model.certifiedClass'],

    model: null,
    showIcon: true,
    showSource: false,
    showDescription: true,
    active: true,
    srcSet: false,

    didRender: function didRender() {
      var _this = this;

      if (!this.get('srcSet')) {
        this.set('srcSet', true);
        var $icon = this.$('.catalog-icon > img');

        $icon.attr('src', $icon.data('src'));
        this.$('img').on('error', function () {
          $icon.attr('src', _this.get('app.baseAssets') + 'assets/images/generic-catalog.svg');
        });
      }
    }
  });
});