define('ui/components/modal-drain-node/component', ['exports', 'shared/mixins/modal-base', 'ui/components/modal-drain-node/template'], function (exports, _modalBase, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_modalBase.default, {
    growl: Ember.inject.service(),

    layout: _template.default,
    classNames: ['large-modal'],

    model: Ember.computed.alias('modalService.modalOpts.originalModel'),

    didReceiveAttrs: function didReceiveAttrs() {
      var options = {
        force: false,
        ignoreDaemonSets: true,
        deleteLocalData: false,
        gracePeriod: -1,
        timeout: 60
      };

      Ember.set(this, 'options', options);
    },


    actions: {
      save: function save(cb) {
        var _this = this;

        Ember.get(this, 'model').doAction('drain', Ember.get(this, 'options')).then(function () {
          _this.send('cancel');
        }).finally(function () {
          cb();
        });
      }
    }
  });
});