define('ui/components/modal-edit-namespace/component', ['exports', 'shared/mixins/new-or-edit', 'shared/mixins/modal-base', 'ui/components/modal-edit-namespace/template'], function (exports, _newOrEdit, _modalBase, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_modalBase.default, _newOrEdit.default, {
    scope: Ember.inject.service(),

    layout: _template.default,
    classNames: ['large-modal'],
    editing: true,
    model: null,

    allNamespaces: null,
    allProjects: null,
    tags: null,

    originalModel: Ember.computed.alias('modalService.modalOpts'),
    init: function init() {
      this._super.apply(this, arguments);

      var orig = Ember.get(this, 'originalModel');
      var clone = orig.clone();

      delete clone.services;

      Ember.setProperties(this, {
        model: clone,
        tags: (Ember.get(this, 'primaryResource.tags') || []).join(','),
        allNamespaces: Ember.get(this, 'clusterStore').all('namespace'),
        allProjects: Ember.get(this, 'globalStore').all('project').filterBy('clusterId', Ember.get(this, 'scope.currentCluster.id'))
      });
    },


    actions: {
      addTag: function addTag(tag) {
        var tags = Ember.get(this, 'primaryResource.tags') || [];

        tags.addObject(tag);

        Ember.set(this, 'tags', tags.join(','));
      },
      updateNsQuota: function updateNsQuota(quota) {
        if (quota) {
          Ember.set(this, 'primaryResource.resourceQuota', { limit: quota });
        } else {
          Ember.set(this, 'primaryResource.resourceQuota', null);
        }
      }
    },

    projectDidChange: Ember.observer('primaryResource.project.id', function () {
      var _this = this;

      Ember.set(this, 'switchingProject', true);
      Ember.run.next(function () {
        Ember.set(_this, 'switchingProject', false);
      });
      if (!Ember.get(this, 'primaryResource.project.resourceQuota')) {
        Ember.set(this, 'primaryResource.resourceQuota', null);
      }
    }),

    tagsDidChanged: Ember.observer('tags', function () {
      Ember.set(this, 'primaryResource.tags', Ember.get(this, 'tags').split(',') || []);
    }),

    projectLimit: Ember.computed('primaryResource.resourceQuota.{limit}', 'primaryResource.projectId', function () {
      var projectId = Ember.get(this, 'primaryResource.projectId');
      var project = Ember.get(this, 'allProjects').findBy('id', projectId);

      return Ember.get(project, 'resourceQuota.limit');
    }),

    projectUsedLimit: Ember.computed('primaryResource.resourceQuota.{limit}', 'primaryResource.projectId', function () {
      var projectId = Ember.get(this, 'primaryResource.projectId');
      var project = Ember.get(this, 'allProjects').findBy('id', projectId);

      return Ember.get(project, 'resourceQuota.usedLimit');
    }),

    nsDefaultQuota: Ember.computed('primaryResource.resourceQuota.{limit}', 'primaryResource.projectId', function () {
      var projectId = Ember.get(this, 'primaryResource.projectId');
      var project = Ember.get(this, 'allProjects').findBy('id', projectId);

      return Ember.get(project, 'namespaceDefaultResourceQuota.limit');
    }),

    validate: function validate() {
      this._super();

      var errors = Ember.get(this, 'errors') || [];
      var quotaErrors = Ember.get(this, 'primaryResource').validateResourceQuota(Ember.get(this, 'originalModel.resourceQuota.limit'));

      if (quotaErrors.length > 0) {
        errors.pushObjects(quotaErrors);
      }

      Ember.set(this, 'errors', errors);

      return Ember.get(this, 'errors.length') === 0;
    },
    doneSaving: function doneSaving() {
      this.send('cancel');
    }
  });
});