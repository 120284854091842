define('ui/components/container/form-networking/component', ['exports', 'ui/components/container/form-networking/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    // Inputs
    instance: null,
    service: null,
    errors: null,
    editing: null,

    initHostAliasesArray: [],

    classNames: ['accordion-wrapper'],

    init: function init() {
      this._super.apply(this, arguments);
      this.initHostAliases();
    },


    actions: {
      hostAliasesChanged: function hostAliasesChanged(hostAliases) {
        var out = [];

        hostAliases.filter(function (alias) {
          return alias.value && alias.key;
        }).forEach(function (alias) {
          out.push({
            hostnames: [alias.value],
            ip: alias.key
          });
        });
        Ember.set(this, 'service.hostAliases', out);
      }
    },

    initHostAliases: function initHostAliases() {
      var _this = this;

      var aliases = Ember.get(this, 'service.hostAliases');

      Ember.set(this, 'initHostAliasesArray', []);
      (aliases || []).forEach(function (alias) {
        (alias.hostnames || []).forEach(function (hostname) {
          Ember.get(_this, 'initHostAliasesArray').push({
            key: alias.ip,
            value: hostname
          });
        });
      });
    }
  });
});