define('ui/models/certificate', ['exports', 'ember-api-store/models/resource'], function (exports, _resource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _resource.default.extend({
    router: Ember.inject.service(),
    intl: Ember.inject.service(),

    state: 'active',

    issuedDate: Ember.computed('issuedAt', function () {
      return new Date(Ember.get(this, 'issuedAt'));
    }),

    expiresDate: Ember.computed('expiresAt', function () {
      return new Date(Ember.get(this, 'expiresAt'));
    }),

    expiresSoon: Ember.computed('expiresDate', function () {
      var diff = Ember.get(this, 'expiresDate').getTime() - new Date().getTime();
      var days = diff / (86400 * 1000);

      return days <= 8;
    }),

    displayIssuer: Ember.computed('issuer', function () {
      return (Ember.get(this, 'issuer') || '').split(/,/)[0].replace(/^CN=/i, '');
    }),

    // All the SANs that aren't the CN
    displaySans: Ember.computed('cn', 'subjectAlternativeNames.[]', function () {
      var sans = Ember.get(this, 'subjectAlternativeNames') || '';
      var cn = Ember.get(this, 'cn') || '';

      if (!sans) {
        return [];
      }

      return sans.removeObject(cn).filter(function (san) {
        return ('' + san).indexOf('@') === -1;
      });
    }),

    // The useful SANs; Removes "domain.com" when the cert is for "www.domain.com"
    countableSans: Ember.computed('displaySans.[]', 'cn', function () {
      var sans = Ember.get(this, 'displaySans').slice();

      if (Ember.get(this, 'cn')) {
        sans.pushObject(Ember.get(this, 'cn'));
      }

      var commonBases = sans.filter(function (name) {
        return name.indexOf('*.') === 0 || name.indexOf('www.') === 0;
      }).map(function (name) {
        return name.substr(name.indexOf('.'));
      });

      return Ember.get(this, 'displaySans').slice().removeObjects(commonBases);
    }),

    // "cn.com and 5 others" (for table view)
    displayDomainName: Ember.computed('cn', 'countableSans.length', function () {
      var intl = Ember.get(this, 'intl');
      var cn = Ember.get(this, 'cn');

      if (!cn) {
        return intl.t('generic.none');
      }

      var sans = Ember.get(this, 'countableSans.length');
      var wildcard = cn.substr(0, 1) === '*';

      var key = void 0;

      if (wildcard) {
        if (sans) {
          key = 'certificatesPage.domainNames.wildcardWithSan';
        } else {
          key = 'certificatesPage.domainNames.wildcardSingle';
        }
      } else if (sans) {
        key = 'certificatesPage.domainNames.withSan';
      } else {
        key = 'certificatesPage.domainNames.single';
      }

      return intl.t(key, {
        cn: cn,
        sans: sans
      });
    }),

    // "user-provided-name (cn-if-different-than-user-name.com + 5 others)"
    displayDetailedName: Ember.computed('displayName', 'cn', 'countableSans.length', function () {
      var name = Ember.get(this, 'displayName');
      var cn = Ember.get(this, 'cn');
      var sans = Ember.get(this, 'countableSans.length');
      var out = name;

      var more = '';

      if (cn) {
        if (cn !== name) {
          more += cn;
        }

        if (sans > 0) {
          more += ' + ' + sans + ' other' + (sans === 1 ? '' : 's');
        }
      }

      if (more) {
        out += ' (' + more + ')';
      }

      return out;
    }),
    actions: {
      edit: function edit() {
        Ember.get(this, 'router').transitionTo('authenticated.project.certificates.detail.edit', Ember.get(this, 'id'));
      }
    }

  });
});