define('ui/components/input-answers/component', ['exports', 'ui/utils/platform', 'shared/utils/evaluate', 'ui/components/input-answers/template'], function (exports, _platform, _evaluate, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var HIDDEN = 'Hidden';

  exports.default = Ember.Component.extend({
    growl: Ember.inject.service(),

    layout: _template.default,
    pasteOrUpload: false,
    accept: '.yml, .yaml',
    showHeader: true,
    answerSections: null,

    originQuestions: Ember.computed.alias('selectedTemplate.questions'),
    questions: Ember.computed.alias('selectedTemplate.allQuestions'),
    customAnswers: Ember.computed.alias('selectedTemplate.customAnswers'),

    actions: {
      upload: function upload() {
        this.$('INPUT[type=file]')[0].click();
      },
      showPaste: function showPaste() {
        Ember.set(this, 'pasteOrUpload', true);
      },
      cancel: function cancel() {
        Ember.set(this, 'pasteOrUpload', false);
      }
    },

    pastedAnswers: Ember.computed('pasteOrUpload', {
      get: function get() /* key */{
        var questions = Ember.get(this, 'questions');
        var answers = Ember.get(this, 'customAnswers') || {};
        var out = {};

        questions.forEach(function (q) {
          out[q.variable] = q.answer || q.default || '';
        });

        Object.keys(answers).forEach(function (key) {
          if (typeof out[key] === 'undefined') {
            out[key] = answers[key];
          }
        });

        return YAML.stringify(out);
      },
      set: function set(key, value) {
        var qa = void 0;

        try {
          qa = YAML.parse(value);
        } catch (err) {
          Ember.set(this, 'yamlErrors', ['YAML Parse Error: ' + err.snippet + ' - ' + err.message]);

          return value;
        }

        Ember.set(this, 'yamlErrors', []);

        var questions = Ember.get(this, 'questions');
        var answers = {};

        if (qa) {
          Object.keys(qa).forEach(function (q) {
            var question = questions.findBy('variable', q);

            if (question) {
              Ember.set(question, 'answer', qa[q]);
            } else {
              answers[q] = qa[q];
            }
          });
        }
        Ember.set(this, 'customAnswers', answers);

        return value;
      }
    }),

    actualAccept: Ember.computed('accept', function () {
      if (_platform.isSafari) {
        return '';
      } else {
        return Ember.get(this, 'accept');
      }
    }),

    answerDidChange: Ember.on('init', Ember.observer('questions.@each.answer', function () {
      var questions = Ember.get(this, 'questions') || [];
      var oldQuestions = [];
      var newQuestions = [];

      (Ember.get(this, 'answerSections') || []).forEach(function (section) {
        section.data.forEach(function (group) {
          group.forEach(function (q) {
            oldQuestions.push(q.variable);
          });
        });
      });
      oldQuestions = oldQuestions.sort();

      var filteredQuestions = questions.filter(function (q) {
        return (0, _evaluate.evaluate)(q, questions);
      });

      newQuestions = filteredQuestions.map(function (q) {
        return q.variable;
      }).sort();

      var sections = {};
      var notInAnySection = [];

      filteredQuestions.forEach(function (item) {
        if (item.group) {
          if (item.group === HIDDEN) {
            return;
          }

          if (!sections[item.group]) {
            sections[item.group] = [];
          }
          sections[item.group].push(item);
        } else {
          notInAnySection.push(item);
        }
      });
      var allSections = [];

      Object.keys(sections).forEach(function (key) {
        allSections.push({
          title: key,
          data: sections[key]
        });
      });

      if (notInAnySection.length) {
        allSections.push({ data: notInAnySection });
      }

      var out = [];

      allSections.forEach(function (section) {
        var data = [];
        var dataIndex = 0;

        out.push({
          title: section.title,
          data: data
        });

        section.data.forEach(function (item, index) {
          if (index % 2 === 0) {
            data.push([item]);
            dataIndex++;
          } else {
            data[dataIndex - 1].push(item);
          }
        });
      });

      if (newQuestions.toString() !== oldQuestions.toString()) {
        Ember.set(this, 'answerSections', out);
      }
    })),

    change: function change(event) {
      var _this = this;

      if (Ember.get(this, 'pasteOrUpload')) {
        return;
      }

      var input = event.target;

      if (input.files && input.files[0]) {
        var file = input.files[0];

        var reader = new FileReader();

        reader.onload = function (event2) {
          var out = event2.target.result;

          Ember.set(_this, 'pastedAnswers', out);
          input.value = '';
        };

        reader.onerror = function (err) {
          Ember.get(_this, 'growl').fromError(Ember.get(err, 'srcElement.error.message'));
        };

        reader.readAsText(file);
      }
    }
  });
});