define('ui/models/service', ['exports', 'ember-api-store/models/resource', 'ember-api-store/utils/denormalize'], function (exports, _resource, _denormalize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _resource.default.extend({
    intl: Ember.inject.service(),
    scope: Ember.inject.service(),
    clusterStore: Ember.inject.service(),

    canEditYaml: true,

    namespace: (0, _denormalize.reference)('namespaceId', 'namespace', 'clusterStore'),

    displayKind: Ember.computed('intl.locale', 'kind', function () {
      var intl = Ember.get(this, 'intl');

      if (Ember.get(this, 'kind') === 'LoadBalancer') {
        return intl.t('model.service.displayKind.loadBalancer');
      } else {
        return intl.t('model.service.displayKind.generic');
      }
    }),

    proxyEndpoints: Ember.computed('labels', function () {
      var _this = this;

      var parts = [];
      var labels = Ember.get(this, 'labels');
      var location = window.location;

      if (labels && labels['kubernetes.io/cluster-service'] === 'true') {
        (Ember.get(this, 'ports') || []).forEach(function (port) {
          var linkEndpoint = location.origin + '/k8s/clusters/' + Ember.get(_this, 'scope.currentCluster.id') + '/api/v1/namespaces/' + Ember.get(_this, 'namespaceId') + '/services/' + Ember.get(port, 'name') + ':' + Ember.get(_this, 'name') + ':' + Ember.get(port, 'port') + '/proxy/';

          parts.push({
            linkEndpoint: linkEndpoint,
            displayEndpoint: '/index.html',
            protocol: location.protocol.substr(0, location.protocol.length - 1),
            isTcpish: true,
            isReady: true
          });
        });
      }

      return parts;
    })
  });
});