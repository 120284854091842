define('ui/utils/parse-version', ['exports', 'shared/utils/parse-version', 'npm:semver'], function (exports, _parseVersion, _npmSemver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.minorVersion = exports.compare = exports.comparePart = exports.parse = exports.satisfies = undefined;
  Object.defineProperty(exports, 'satisfies', {
    enumerable: true,
    get: function () {
      return _parseVersion.satisfies;
    }
  });
  Object.defineProperty(exports, 'parse', {
    enumerable: true,
    get: function () {
      return _parseVersion.parse;
    }
  });
  Object.defineProperty(exports, 'comparePart', {
    enumerable: true,
    get: function () {
      return _parseVersion.comparePart;
    }
  });
  Object.defineProperty(exports, 'compare', {
    enumerable: true,
    get: function () {
      return _parseVersion.compare;
    }
  });
  Object.defineProperty(exports, 'minorVersion', {
    enumerable: true,
    get: function () {
      return _parseVersion.minorVersion;
    }
  });
});