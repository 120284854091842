define('ui/authenticated/project/security/members/index/controller', ['exports', 'ui/mixins/filter-state'], function (exports, _filterState) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var headers = [{
    translationKey: 'membersPage.index.table.userId',
    name: 'user.displayName',
    sort: ['user.dispalyName', 'name', 'id']
  }, {
    translationKey: 'membersPage.index.table.template',
    name: 'roleTemplateId',
    sort: ['roleTemplateId']
  }, {
    translationKey: 'generic.created',
    name: 'created',
    sort: ['created'],
    classNames: 'text-right pr-20',
    width: 200
  }];

  exports.default = Ember.Controller.extend(_filterState.default, {
    scope: Ember.inject.service(),
    sortBy: 'name',
    headers: headers,
    searchText: '',
    filterableContent: Ember.computed('model.projectRoleTemplateBindings.[]', function () {
      return Ember.get(this, 'model.projectRoleTemplateBindings');
    })
  });
});