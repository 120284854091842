define('ui/models/activedirectoryconfig', ['exports', 'ember-api-store/models/resource'], function (exports, _resource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ActiveDirectoryConfig = _resource.default.extend({ type: 'activeDirectoryConfig' });

  exports.default = ActiveDirectoryConfig;
});