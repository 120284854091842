define('ui/models/project', ['exports', 'ember-api-store/utils/denormalize', 'ember-api-store/models/resource', 'ui/utils/constants'], function (exports, _denormalize, _resource, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SYSTEM_PROJECT_LABEL = 'authz.management.cattle.io/system-project';

  exports.default = _resource.default.extend({
    access: Ember.inject.service(),
    prefs: Ember.inject.service(),
    scope: Ember.inject.service(),
    settings: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    router: Ember.inject.service(),
    clusterStore: Ember.inject.service(),

    projectRoleTemplateBindings: (0, _denormalize.hasMany)('id', 'projectRoleTemplateBinding', 'projectId'),
    apps: (0, _denormalize.hasMany)('id', 'apps', 'projectId'),

    namespaces: (0, _denormalize.hasMany)('id', 'namespace', 'projectId', 'clusterStore'),

    type: 'project',
    name: null,
    description: null,

    cluster: (0, _denormalize.reference)('clusterId', 'cluster'),
    // 2.0 bug projectId is wrong in the ptrb should be <cluster-id>:<project-id> instead of just <project-id>
    roleTemplateBindings: Ember.computed.alias('projectRoleTemplateBindings'),
    icon: Ember.computed('active', function () {
      if (Ember.get(this, 'active')) {
        return 'icon icon-folder-open';
      } else {
        return 'icon icon-folder text-muted';
      }
    }),

    isDefault: Ember.computed('prefs.' + _constants.default.PREFS.PROJECT_DEFAULT, 'id', function () {
      return Ember.get(this, 'prefs.' + _constants.default.PREFS.PROJECT_DEFAULT) === Ember.get(this, 'id');
    }),

    isSystemProject: Ember.computed('labels', function () {
      var labels = Ember.get(this, 'labels') || {};

      return labels[SYSTEM_PROJECT_LABEL] === 'true';
    }),

    active: Ember.computed('scope.currentProject.id', 'id', function () {
      return Ember.get(this, 'scope.currentProject.id') === Ember.get(this, 'id');
    }),

    canSetDefault: Ember.computed('combinedState', 'isDefault', function () {
      return Ember.get(this, 'combinedState') === 'active' && !Ember.get(this, 'isDefault');
    }),
    actions: {
      edit: function edit() {
        Ember.get(this, 'router').transitionTo('authenticated.cluster.projects.edit', Ember.get(this, 'id'));
      },
      activate: function activate() {
        return this.doAction('activate');
      },
      deactivate: function deactivate() {
        var _this = this;

        return this.doAction('deactivate').then(function () {
          if (Ember.get(_this, 'scope.currentProject') === _this) {
            window.location.href = window.location.href;
          }
        });
      },
      setAsDefault: function setAsDefault() {
        Ember.set(Ember.get(this, 'prefs'), _constants.default.PREFS.PROJECT_DEFAULT, Ember.get(this, 'id'));
      },
      promptStop: function promptStop() {
        Ember.get(this, 'modalService').toggleModal('modal-confirm-deactivate', {
          originalModel: this,
          action: 'deactivate'
        });
      }
    },

    delete: function _delete() /* arguments*/{
      var _this2 = this;

      var promise = this._super.apply(this, arguments);

      return promise.then(function () {
        if (Ember.get(_this2, 'active')) {
          window.location.href = window.location.href;
        }
      }).catch(function (err) {
        Ember.get(_this2, 'growl').fromError('Error deleting', err);
      });
    }
  });
});