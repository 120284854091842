define('ui/components/modal-telemetry/component', ['exports', 'ui/utils/constants', 'shared/mixins/modal-base', 'ui/components/modal-telemetry/template'], function (exports, _constants, _modalBase, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_modalBase.default, {
    settings: Ember.inject.service(),
    layout: _template.default,
    classNames: ['modal-container', 'span-6', 'offset-3', 'modal-telemetry', 'alert'],
    optIn: null,

    init: function init() {
      this._super.apply(this, arguments);

      var cur = Ember.get(this, 'settings.' + _constants.default.SETTING.TELEMETRY);
      var version = Ember.get(this, 'settings.rancherVersion');
      var optIn = void 0;

      if (version && version !== 'master') {
        // For releases, default to opt in
        optIn = cur !== 'out';
      } else {
        // For master, default to opt out
        optIn = cur === 'in';
      }

      Ember.set(this, 'optIn', optIn);
    },


    actions: {
      cancel: function cancel() {
        Ember.get(this, 'settings').set(_constants.default.SETTING.TELEMETRY, Ember.get(this, 'optIn') ? 'in' : 'out');
        Ember.get(this, 'modalService').toggleModal();
      }
    }
  });
});