define('ui/authenticated/project/dns/detail/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      var store = Ember.get(this, 'store');

      return Ember.RSVP.hash({
        dnsRecords: store.findAll('dnsRecord'),
        workloads: store.findAll('workload'),
        record: store.find('dnsRecord', params.record_id)
      });
    }
  });
});