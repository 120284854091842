define('ui/components/page-header-environment/component', ['exports', 'ui/components/page-header-environment/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    access: Ember.inject.service(),
    scope: Ember.inject.service(),
    globalStore: Ember.inject.service(),

    layout: _template.default,
    pageScope: null,

    tagName: 'LI',
    classNames: ['dropdown', 'nav-item', 'nav-cluster'],
    classNameBindings: ['hide'],

    project: Ember.computed.alias('scope.pendingProject'),
    cluster: Ember.computed.alias('scope.pendingCluster'),
    twoLine: Ember.computed('pageScope', function () {
      return this.get('pageScope') === 'project';
    }),

    hide: Ember.computed('pageScope', function () {
      return this.get('pageScope') === 'user';
    }),

    projectChoices: Ember.computed('scope.allProjects.@each.{id,displayName,relevantState}', function () {
      return this.get('scope.allProjects').filterBy('relevantState', 'active').sortBy('displayName', 'id');
    }),

    byCluster: Ember.computed('scope.allClusters.@each.id', 'projectChoices.@each.clusterId', 'cluster.id', function () {
      var currentClusterId = this.get('cluster.id');
      var out = [];

      this.get('scope.allClusters').forEach(function (cluster) {
        getOrAddCluster(cluster);
      });

      this.get('projectChoices').forEach(function (project) {
        var cluster = project.get('cluster');

        if (!cluster) {
          return;
        }

        var entry = getOrAddCluster(cluster);

        entry.projects.push(project);
      });

      return out.sortBy('cluster.sortName');

      function getOrAddCluster(cluster) {
        var clusterId = cluster.get('id');
        var entry = out.findBy('clusterId', clusterId);

        if (!entry) {
          entry = {
            clusterId: clusterId,
            cluster: cluster,
            projects: [],
            active: clusterId === currentClusterId
          };

          out.push(entry);
        }

        return entry;
      }
    }),

    projectIsMissing: Ember.computed('project.id', 'projectChoices.@each.id', function () {
      return false;
    }
    // const projectId = this.get('project.id');
    // return projectId && this.get('projectChoices').filterBy('id', projectId).get('length') === 0;
    )
  });
});