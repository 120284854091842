define('ui/verify-auth/route', ['exports', 'shared/utils/constants', 'ui/mixins/verify-auth'], function (exports, _constants, _verifyAuth) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var samlProviders = ['ping', 'adfs', 'keycloak'];

  exports.default = Ember.Route.extend(_verifyAuth.default, {
    github: Ember.inject.service(),

    model: function model(params /* , transition */) {
      var _this = this;

      if (window.opener && !Ember.get(params, 'login')) {
        var occName = Ember.get(params, 'config') ? 'security.authentication.' + Ember.get(params, 'config') : 'security.authentication.github';
        var openerController = window.opener.lc(occName);
        var openerStore = Ember.get(openerController, 'globalStore');
        var qp = Ember.get(params, 'config') || Ember.get(params, 'authProvider');
        var type = qp + 'Config';
        var config = openerStore.getById(type, qp);
        var gh = Ember.get(this, 'github');
        var stateMsg = 'Authorization state did not match, please try again.';

        if (Ember.get(params, 'config') === 'github') {
          return gh.testConfig(config).then(function (resp) {
            gh.authorize(resp, openerController.get('github.state'));
          }).catch(function (err) {
            _this.send('gotError', err);
          });
        } else if (samlProviders.includes(Ember.get(params, 'config'))) {
          if (window.opener.window.onAuthTest) {
            reply(null, config);
          } else {
            reply({ err: 'failure' });
          }
        }

        if (Ember.get(params, 'code')) {
          // TODO see if session.githubState works here
          if (openerController.get('github').stateMatches(Ember.get(params, 'state'))) {
            reply(params.error_description, params.code);
          } else {
            reply(stateMsg);
          }
        }
      }

      if (Ember.get(params, 'code') && Ember.get(params, 'login')) {
        if (Ember.get(this, 'github').stateMatches(Ember.get(params, 'state'))) {
          var ghProvider = Ember.get(this, 'access.providers').findBy('id', 'github');

          return ghProvider.doAction('login', {
            code: Ember.get(params, 'code'),
            responseType: 'cookie',
            description: _constants.default.SESSION.DESCRIPTION,
            ttl: _constants.default.SESSION.TTL
          }).then(function () {
            return Ember.get(_this, 'access').detect().then(function () {
              return _this.transitionTo('authenticated');
            });
          });
        }
      }

      function reply(err, code) {
        try {
          window.opener.window.onAuthTest(err, code);
          setTimeout(function () {
            window.close();
          }, 250);

          return new Ember.RSVP.promise();
        } catch (e) {
          window.close();
        }
      }
    }
  });
});