define('ui/components/cru-secret/component', ['exports', 'shared/mixins/view-new-edit', 'shared/mixins/optionally-namespaced', 'ui/components/cru-secret/template'], function (exports, _viewNewEdit, _optionallyNamespaced, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_viewNewEdit.default, _optionallyNamespaced.default, {
    layout: _template.default,
    model: null,
    namespace: null,
    namespacedType: 'namespacedSecret',
    projectType: 'secret',
    scope: 'project',
    titleKey: 'newSecret.title',

    init: function init() {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'model.type') === 'namespacedSecret') {
        Ember.set(this, 'scope', 'namespace');
      }
    },


    actions: {
      updateData: function updateData(map) {
        Ember.set(this, 'primaryResource.data', map);
      }
    },

    willSave: function willSave() {
      var pr = Ember.get(this, 'primaryResource');

      // Namespace is required, but doesn't exist yet... so lie to the validator
      var nsId = Ember.get(pr, 'namespaceId');

      Ember.set(pr, 'namespaceId', '__TEMP__');
      var ok = this.validate();

      Ember.set(pr, 'namespaceId', nsId);

      return ok;
    },
    validate: function validate() {
      this._super();

      var errors = Ember.get(this, 'errors') || [];

      if (Ember.get(this, 'scope') !== 'project') {
        errors.pushObjects(Ember.get(this, 'namespaceErrors') || []);
      }
      Ember.set(this, 'errors', errors);

      return errors.length === 0;
    },
    doSave: function doSave() {
      var _arguments = arguments;

      var self = this;
      var sup = self._super;

      return this.namespacePromise().then(function () {
        return sup.apply(self, _arguments);
      });
    },
    doneSaving: function doneSaving() {
      this.sendAction('cancel');
    }
  });
});