define('ui/models/projectalert', ['exports', 'ember-api-store/models/resource', 'ui/mixins/model-alert'], function (exports, _resource, _modelAlert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ProjectAlert = _resource.default.extend(_modelAlert.default, {
    intl: Ember.inject.service(),
    projectStore: Ember.inject.service('store'),
    canClone: true,

    type: 'projectalert',
    // _targetType is used for edit,
    _targetType: 'pod',

    displayTargetType: function () {
      var t = Ember.get(this, 'targetType');
      var intl = Ember.get(this, 'intl');

      return intl.t('alertPage.targetTypes.' + t);
    }.property('targetType'),

    podName: function () {
      var id = Ember.get(this, 'targetPod.podId');
      var pod = Ember.get(this, 'projectStore').all('pod').filterBy('id', id).get('firstObject');

      if (!pod) {
        return null;
      }

      return pod.get('displayName');
    }.property('targetPod.podId'),

    workloadName: function () {
      var id = Ember.get(this, 'targetWorkload.workloadId');
      var workload = Ember.get(this, 'projectStore').all('workload').filterBy('id', id).get('firstObject');

      if (!workload) {
        return null;
      }

      return workload.get('displayName');
    }.property('targetWorkload.workloadId'),

    displayCondition: function () {
      var t = Ember.get(this, 'targetType');
      var intl = Ember.get(this, 'intl');

      if (t === 'pod') {
        var c = Ember.get(this, 'targetPod.condition');

        if (c === 'restarts') {
          var times = Ember.get(this, 'targetPod.restartTimes');
          var interval = Ember.get(this, 'targetPod.restartIntervalSeconds');

          return intl.t('alertPage.index.table.displayCondition.restarted', {
            times: times,
            interval: interval / 60
          });
        }
        if (c === 'notscheduled') {
          return intl.t('alertPage.index.table.displayCondition.notScheduled');
        }
        if (c === 'notrunning') {
          return intl.t('alertPage.index.table.displayCondition.notRunning');
        }

        return intl.t('alertPage.na');
      }
      if (t === 'workload' || t === 'workloadSelector') {
        var percent = Ember.get(this, 'targetWorkload.availablePercentage');

        return intl.t('alertPage.index.table.displayCondition.available', { percent: percent });
      }
    }.property('targetType', 'targetPod.{condition,restartTimes,restartIntervalSeconds}', 'targetWorkload.{availablePercentage}'),

    targetType: function () {
      var tp = Ember.get(this, 'targetPod');
      var tw = Ember.get(this, 'targetWorkload');

      if (tp && tp.podId) {
        return 'pod';
      }
      if (tw && tw.workloadId) {
        return 'workload';
      }
      if (tw && tw.selector) {
        return 'workloadSelector';
      }
    }.property('targetPod.{podId}', 'targetWorkload.{workloadId,selector}'),

    actions: {
      clone: function clone() {
        Ember.get(this, 'router').transitionTo('authenticated.project.alert.new', { queryParams: { id: Ember.get(this, 'id') } });
      }
    }

  });

  exports.default = ProjectAlert;
});