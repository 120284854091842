define('ui/models/pod', ['exports', 'ui/utils/constants', 'ember-api-store/models/resource', 'ember-api-store/utils/denormalize', 'ui/utils/util', 'shared/utils/parse-unit', 'shared/mixins/display-image'], function (exports, _constants, _resource, _denormalize, _util, _parseUnit, _displayImage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.stoppedIcon = stoppedIcon;
  exports.stoppedColor = stoppedColor;


  var Pod = _resource.default.extend(_displayImage.default, {
    router: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    globalStore: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    scope: Ember.inject.service(),

    canHaveLabels: true,
    escToClose: true,

    canEdit: false,
    canClone: false,

    namespace: (0, _denormalize.reference)('namespaceId', 'namespace', 'clusterStore'),
    node: (0, _denormalize.reference)('nodeId', 'node', 'globalStore'),
    workload: (0, _denormalize.reference)('workloadId'),
    hasSidekicks: Ember.computed.gt('containers.length', 1),
    canEditYaml: Ember.computed('links.update', 'links.yaml', function () {
      return !!Ember.get(this, 'links.update') && !!Ember.get(this, 'links.yaml');
    }),

    availableActions: Ember.computed('combinedState', function () {
      var isRunning = Ember.get(this, 'combinedState') === 'running';

      var choices = [{
        label: 'action.execute',
        icon: 'icon icon-terminal',
        action: 'shell',
        enabled: isRunning,
        altAction: 'popoutShell'
      }, {
        label: 'action.logs',
        icon: 'icon icon-file',
        action: 'logs',
        enabled: true,
        altAction: 'popoutLogs'
      }];

      return choices;
    }),

    memoryReservationBlurb: Ember.computed('memoryReservation', function () {
      if (Ember.get(this, 'memoryReservation')) {
        return (0, _parseUnit.formatSi)(Ember.get(this, 'memoryReservation'), 1024, 'iB', 'B');
      }
    }),

    combinedState: Ember.computed('node.state', 'workload.state', 'state', 'healthState', 'healthCheck', function () {
      var node = Ember.get(this, 'node.state');
      var resource = Ember.get(this, 'state');
      // var workload = get(this,'workload.state');
      var health = Ember.get(this, 'healthState');
      var hasCheck = !!Ember.get(this, 'healthCheck');

      if (!hasCheck && _constants.default.DISCONNECTED_STATES.includes(node)) {
        return 'unknown';
      } else if (_constants.default.ACTIVEISH_STATES.includes(resource) && health) {
        return health;
      } else {
        return resource;
      }
    }),

    isOn: function () {
      return ['running', 'migrating', 'restarting'].indexOf(Ember.get(this, 'state')) >= 0;
    }.property('state'),

    displayState: Ember.computed('_displayState', 'exitCode', function () {
      var out = Ember.get(this, '_displayState');
      var code = Ember.get(this, 'exitCode');

      if (Ember.get(this, 'state') === 'stopped' && Ember.get(this, 'exitCode') > 0) {
        out += ' (' + code + ')';
      }

      return out;
    }),

    displayEnvironmentVars: Ember.computed('environment', function () {
      var envs = [];
      var environment = Ember.get(this, 'environment') || {};

      Object.keys(environment).forEach(function (key) {
        envs.pushObject({
          key: key,
          value: environment[key]
        });
      });

      return envs;
    }),

    displayIp: function () {
      return Ember.get(this, 'status.podIp') || null;
    }.property('status.podIp'),

    nodeIp: function () {
      return Ember.get(this, 'status.nodeIp') || null;
    }.property('status.nodeIp'),

    sortIp: function () {
      var ip = Ember.get(this, 'primaryAssociatedIpAddress') || Ember.get(this, 'primaryIpAddress');

      if (!ip) {
        return '';
      }
      var match = ip.match(/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/);

      if (match) {
        return match.slice(1).map(function (octet) {
          return (0, _util.strPad)(octet, 3, '0', false);
        }).join('.');
      }
    }.property('primaryIpAddress', 'primaryAssociatedIpAddress'),

    isGlobalScale: function () {
      return '' + (Ember.get(this, 'labels') || {})[_constants.default.LABEL.SCHED_GLOBAL] === 'true';
    }.property('labels'),

    actions: {
      clone: function clone() {
        Ember.get(this, 'router').transitionTo('containers.run', { queryParams: { podId: Ember.get(this, 'id') } });
      },
      shell: function shell() {
        Ember.get(this, 'modalService').toggleModal('modal-shell', { model: this });
      },
      popoutShell: function popoutShell() {
        var projectId = Ember.get(this, 'scope.currentProject.id');
        var podId = Ember.get(this, 'id');
        var route = Ember.get(this, 'router').urlFor('authenticated.project.console', projectId);

        var system = Ember.get(this, 'node.info.os.operatingSystem') || '';
        var windows = false;

        if (system.startsWith('Windows')) {
          windows = true;
        }

        Ember.run.later(function () {
          window.open('//' + window.location.host + route + '?podId=' + podId + '&windows=' + windows + '&isPopup=true', '_blank', 'toolbars=0,width=900,height=700,left=200,top=200');
        });
      },
      logs: function logs() {
        Ember.get(this, 'modalService').toggleModal('modal-container-logs', { model: this });
      },
      popoutLogs: function popoutLogs() {
        var projectId = Ember.get(this, 'scope.currentProject.id');
        var podId = Ember.get(this, 'id');
        var route = Ember.get(this, 'router').urlFor('authenticated.project.container-log', projectId);

        Ember.run.later(function () {
          window.open('//' + window.location.host + route + '?podId=' + podId + '&isPopup=true', '_blank', 'toolbars=0,width=900,height=700,left=200,top=200');
        });
      }
    },

    hasLabel: function hasLabel(key, desiredValue) {
      var labels = Ember.get(this, 'labels') || {};
      var value = Ember.get(labels, key);

      if (value === undefined) {
        return false;
      }

      if (desiredValue === undefined) {
        return true;
      }

      return value === desiredValue;
    }
  });

  function stoppedIcon(inst) {
    if (inst.get('restartPolicy.name') === 'no' && inst.get('exitCode') === 0) {
      return 'icon icon-dot-circlefill';
    }

    return 'icon icon-circle';
  }

  function stoppedColor(inst) {
    if (inst.get('restartPolicy.name') === 'no' && inst.get('exitCode') === 0) {
      return 'text-success';
    }

    return 'text-error';
  }

  Pod.reopenClass({
    stateMap: {
      'stopped': {
        icon: stoppedIcon,
        color: stoppedColor
      }
    },

    mangleIn: function mangleIn(data) {
      if (data && data.containers) {
        data.containers.forEach(function (container) {
          container.type = 'container';
          container.podId = data.id;
        });
      }

      return data;
    }
  });

  exports.default = Pod;
});