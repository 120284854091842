define('ui/authenticated/project/dns/index/route', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var store = this.get('store');

      return Ember.RSVP.hash({ records: store.findAll('dnsRecord') });
    },


    setDefaultRoute: Ember.on('activate', function () {
      Ember.set(this, 'session.' + _constants.default.SESSION.CONTAINER_ROUTE, 'authenticated.project.dns');
      Ember.set(this, 'session.' + _constants.default.SESSION.PROJECT_ROUTE, undefined);
    })
  });
});