define('ui/components/container-dot/component', ['exports', 'ui/utils/platform', 'ui/components/container-dot/template'], function (exports, _platform, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    resourceActions: Ember.inject.service('resource-actions'),
    tooltipService: Ember.inject.service('tooltip'),
    router: Ember.inject.service(),

    layout: _template.default,
    model: null,
    tagName: 'div',
    classNames: ['vertical-middle'],
    type: 'tooltip-action-menu',
    template: 'tooltip-container-dot',
    alt: function () {
      return this.get('model.displayName') + ': ' + this.get('model.displayState');
    }.property('model.{displayState,displayName}'),

    resourceActionsObserver: Ember.observer('resourceActions.open', function () {
      if (this.get('tooltipService.openedViaContextClick')) {
        this.get('tooltipService').set('openedViaContextClick', false);
      }
    }).on('init'),
    click: function click(event) {
      this.details(event);
      this.get('tooltipService').hide();
    },
    details: function details() /* event*/{
      var route = 'container';

      if (this.get('model.isVm')) {
        route = 'virtualmachine';
      }

      this.get('router').transitionTo(route, this.get('model.id'));
    },
    contextMenu: function contextMenu(event) {
      if ((0, _platform.isMore)(event)) {
        return;
      }

      event.preventDefault();

      if (this.get('type') === 'tooltip-action-menu') {
        this.get('resourceActions').set('open', true);
        this.get('tooltipService').set('openedViaContextClick', true);
        Ember.$('.container-tooltip .more-actions').trigger('click');
      } else {
        this.get('resourceActions').setActionItems(this.get('model'), {});
      }
    }
  });
});