define('ui/components/cru-dns/component', ['exports', 'shared/mixins/view-new-edit', 'ui/components/cru-dns/template', 'ui/models/dnsrecord', 'shared/mixins/child-hook'], function (exports, _viewNewEdit, _template, _dnsrecord, _childHook) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_viewNewEdit.default, _childHook.default, {
    intl: Ember.inject.service(),

    layout: _template.default,
    model: null,

    recordType: null,
    namespaceErrors: null,
    namespace: Ember.computed.alias('model.namespace'),

    init: function init() {
      this._super.apply(this, arguments);

      var type = Ember.get(this, 'model.recordType') || _dnsrecord.ARECORD;

      Ember.setProperties(this, { recordType: type });
    },


    actions: {
      setAlias: function setAlias(ids) {
        Ember.set(this, 'model.targetDnsRecordIds', ids);
      },
      setWorkload: function setWorkload(ids) {
        Ember.set(this, 'model.targetWorkloadIds', ids);
      },
      setSelector: function setSelector(map) {
        Ember.set(this, 'model.selector', map);
      }
    },

    namespaceDidChange: Ember.observer('namespace.id', function () {
      var _this = this;

      if (Ember.get(this, 'recordType') === 'workload') {
        if (Ember.get(this, 'model.targetWorkloads').some(function (target) {
          return target.namespaceId !== Ember.get(_this, 'namespace.id');
        })) {
          Ember.set(this, 'model.targetWorkloadIds', null);
          Ember.set(this, 'recordType', null);

          Ember.run.next(function () {
            Ember.set(_this, 'recordType', 'workload');
          });
        }
      }
    }),

    /*
    targetServicesAsMaps: null,
    targetIpArray: null,
    stack: null,
    stackErrors: null,
    */

    workloadsChoices: Ember.computed('namespace.id', 'workloads.[]', function () {
      var namespaceId = Ember.get(this, 'namespace.id');

      return (Ember.get(this, 'workloads') || []).filter(function (w) {
        return Ember.get(w, 'namespaceId') === namespaceId;
      });
    }),

    willSave: function willSave() {
      var _this2 = this,
          _arguments = arguments;

      Ember.get(this, 'model').clearTypesExcept(Ember.get(this, 'recordType'));
      Ember.set(this, 'model.namespaceId', Ember.get(this, 'namespace.id') || '__placeholder__');
      var self = this;
      var sup = this._super;

      return this.applyHooks('_beforeSaveHooks').then(function () {
        Ember.set(_this2, 'model.namespaceId', Ember.get(_this2, 'namespace.id'));

        return sup.apply.apply(sup, [self].concat(Array.prototype.slice.call(_arguments)));
      });
    },
    validate: function validate() {
      this._super.apply(this, arguments);
      var errors = Ember.get(this, 'errors') || [];
      var intl = Ember.get(this, 'intl');

      var aliasTargets = Ember.get(this, 'model.targetDnsRecords') || [];
      var aliases = aliasTargets.length;
      var aliasesToCname = aliasTargets.filterBy('recordType', _dnsrecord.CNAME).length;
      var selectorKeys = Object.keys(Ember.get(this, 'model.selector') || {}).length;
      var workloads = (Ember.get(this, 'model.targetWorkloads') || []).length;

      switch (Ember.get(this, 'recordType')) {
        case _dnsrecord.ARECORD:
          if (Ember.get(this, 'model.ipAddresses.length') < 1) {
            errors.pushObject(intl.t('editDns.errors.targetRequired'));
          }
          break;

        case _dnsrecord.CNAME:
          if (!Ember.get(this, 'model.hostname')) {
            errors.pushObject(intl.t('editDns.errors.targetRequired'));
          }
          break;

        case _dnsrecord.ALIAS:
          if (aliases < 1) {
            errors.pushObject(intl.t('editDns.errors.targetRequired'));
          }

          if (aliasesToCname > 1) {
            errors.pushObject(intl.t('editDns.errors.multipleCname'));
          }

          if (aliasesToCname >= 1 && aliases > aliasesToCname) {
            errors.pushObject(intl.t('editDns.errors.mixedAlias'));
          }
          break;

        case _dnsrecord.WORKLOAD:
          if (workloads < 1) {
            errors.pushObject(intl.t('editDns.errors.targetRequired'));
          }
          break;

        case _dnsrecord.SELECTOR:
          if (selectorKeys < 1) {
            errors.pushObject(intl.t('editDns.errors.selectorRequired'));
          }
          break;
      }

      errors.pushObjects(Ember.get(this, 'namespaceErrors') || []);

      Ember.set(this, 'errors', errors);

      return errors.length === 0;
    }
  });
});