define('ui/components/project-group/component', ['exports', 'ui/components/project-group/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    model: null,
    fullColspan: null,
    afterName: 0,
    afterState: 0,
    alignState: 'text-center',
    showActions: true,
    noGroup: 'namespaceGroup.none',
    tagName: ''
  });
});