define('ui/authenticated/route', ['exports', 'ui/utils/constants', 'ui/mixins/preload'], function (exports, _constants, _preload) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var CHECK_AUTH_TIMER = 60 * 10 * 1000;

  exports.default = Ember.Route.extend(_preload.default, {
    access: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    cookies: Ember.inject.service(),
    language: Ember.inject.service('user-language'),
    modalService: Ember.inject.service('modal'),
    prefs: Ember.inject.service(),
    scope: Ember.inject.service(),
    settings: Ember.inject.service(),
    storeReset: Ember.inject.service(),
    intl: Ember.inject.service(),
    growl: Ember.inject.service(),
    userTheme: Ember.inject.service('user-theme'),

    testTimer: null,

    shortcuts: {
      // Global
      'c': 'gotoc',
      'u': 'gotou',
      'shift+k': 'gotoK',
      'shift+p': 'gotoP',
      'shift+t': 'gotoT',

      // Cluster or Proejct
      '`': 'quake',
      'shift+`': 'quake',
      'm': 'gotom',

      // Cluster
      'd': 'gotod',
      'n': 'goton',
      'p': 'gotop',
      'shift+v': 'gotoV',

      // Project
      'w': 'gotow',
      'b': 'gotob',
      's': 'gotos',
      'v': 'gotov',
      'a': 'gotoa',

      // Other
      // 'g': Defined in subroutes
      't': 'nextTab',
      '/': 'search',
      'shift+/': 'help',
      'backspace': 'delete',
      'delete': 'delete'
    },

    beforeModel: function beforeModel() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.set(this, 'testTimer', Ember.run.later(function () {
        _this.testAuthToken();
      }, CHECK_AUTH_TIMER));

      return this.testAuthToken().then(function () {
        if (Ember.get(_this, 'access.mustChangePassword')) {
          _this.transitionTo('update-password');
        }

        return _this.loadPublicSettings().then(function () {
          if (Ember.get(_this, 'settings.serverUrlIsEmpty')) {
            Ember.get(_this, 'router').transitionTo('update-critical-settings');
          }
        });
      });
    },
    model: function model(params, transition) {
      var _this2 = this;

      Ember.get(this, 'session').set(_constants.default.SESSION.BACK_TO, undefined);

      var isPopup = this.controllerFor('application').get('isPopup');

      return Ember.get(this, 'scope').startSwitchToGlobal(!isPopup).then(function () {
        var list = [_this2.loadSchemas('globalStore'), _this2.loadClusters(), _this2.loadProjects(), _this2.loadPreferences()];

        var globalStore = Ember.get(_this2, 'globalStore');

        if (!isPopup) {
          list.addObjects([_this2.preload('node', 'globalStore', { url: 'nodes' }), _this2.preload('nodePool', 'globalStore', { url: 'nodePools' }), _this2.preload('noedTemplates', 'globalStore', { url: 'nodeTemplates' }), _this2.preload('roleTemplate', 'globalStore', {
            url: 'roleTemplates',
            filter: {
              enabled: true,
              hidden: false
            }
          }), _this2.preload('clusterRoleTemplateBindings', 'globalStore', { url: 'clusterRoleTemplateBindings' }), _this2.preload('projectRoleTemplateBinding', 'globalStore', { url: 'projectRoleTemplateBinding' }), _this2.preload('globalRole', 'globalStore', { url: 'globalRole' }), _this2.preload('authConfig', 'globalStore', { url: 'authConfigs' }), _this2.preload('globalRoleBinding', 'globalStore', { url: 'globalRoleBinding' }), _this2.preload('user', 'globalStore', { url: 'user' }), globalStore.findAll('principal').then(function (principals) {
            var me = principals.filter(function (p) {
              return p.me === true;
            });

            if (me.length > 0) {
              Ember.set(_this2, 'access.principal', Ember.get(me, 'firstObject'));
            }
            principals.forEach(function (p) {
              Ember.set(p, '_mine', true);
            });
          })]);
        }

        return Ember.RSVP.all(list);
      }).catch(function (err) {
        return _this2.loadingError(err, transition);
      });
    },
    setupController: function setupController() /* controller, model*/{
      this._super.apply(this, arguments);
      Ember.get(this, 'scope').finishSwitchToGlobal();
    },
    activate: function activate() {
      this._super.apply(this, arguments);

      if (this.controllerFor('application').get('isPopup')) {
        Ember.$('BODY').addClass('popup');

        return;
      }

      if (Ember.get(this, 'settings.isRancher')) {
        var form = Ember.get(this, 'settings.' + _constants.default.SETTING.FEEDBACK_FORM);

        // Show the telemetry opt-in
        var opt = Ember.get(this, 'settings.' + _constants.default.SETTING.TELEMETRY);

        if (Ember.get(this, 'access.admin') && (!opt || opt === 'prompt')) {
          Ember.run.scheduleOnce('afterRender', this, function () {
            Ember.get(this, 'modalService').toggleModal('modal-telemetry');
          });
        } else if (form && !Ember.get(this, 'prefs.' + _constants.default.PREFS.FEEDBACK)) {
          Ember.run.scheduleOnce('afterRender', this, function () {
            Ember.get(this, 'modalService').toggleModal('modal-feedback');
          });
        }
      }
    },
    deactivate: function deactivate() {
      this._super();
      var scope = Ember.get(this, 'scope');

      scope.startSwitchToNothing().then(function () {
        scope.finishSwitchToNothing();
      });
      Ember.run.cancel(Ember.get(this, 'testTimer'));
    },


    actions: {
      error: function error(err, transition) {
        // Unauthorized error, send back to login screen
        if (err && err.status === 401) {
          this.send('logout', transition);

          return false;
        } else {
          // Bubble up
          return true;
        }
      },
      becameReady: function becameReady() {
        // This absorbs became ready in case it's not handled elsewhere
      },
      showAbout: function showAbout() {
        this.controllerFor('application').set('showAbout', true);
      },
      switchProject: function switchProject(projectId) {
        var _this3 = this;

        var transitionTo = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'authenticated';
        var transitionArgs = arguments[2];

        // console.log('Switch to Project:' + projectId);
        Ember.RSVP.all([Ember.get(this, 'scope.subscribeProject').disconnect()]).then(function () {
          // console.log('Switch is disconnected');
          _this3.send('finishSwitch', 'project:' + projectId, transitionTo, transitionArgs);
        });
      },
      finishSwitch: function finishSwitch(id, transitionTo, transitionArgs) {
        // console.log('Switch finishing');

        var cookies = Ember.get(this, 'cookies');

        var _id$split = id.split(':'),
            _id$split2 = _slicedToArray(_id$split, 2),
            whichCookie = _id$split2[0],
            idOut = _id$split2[1];

        Ember.get(this, 'storeReset').reset();

        if (transitionTo) {
          var args = (transitionArgs || []).slice();

          args.unshift(transitionTo);
          this.transitionTo.apply(this, args);
        }

        cookies.set(_constants.default.COOKIE[whichCookie.toUpperCase()], idOut);

        this.refresh();

        // console.log('Switch finished');
      },
      help: function help() {
        Ember.get(this, 'modalService').toggleModal('modal-shortcuts', { escToClose: true });
      },


      // Special
      nextTab: function nextTab() {
        if (Ember.$('.tab-nav').length) {
          var cur = Ember.$('.tab-nav .active');
          var next = cur.closest('li').next().find('a');

          if (next && next.length) {
            next.click();
          } else {
            next = Ember.$('.tab-nav li:first-child a');
            if (next && next.length) {
              next.click();
            }
          }
        }
      },
      search: function search(event) {
        var elem = Ember.$("INPUT[type='search']")[0];

        if (elem) {
          event.stopPropagation();
          event.preventDefault();
          elem.focus();
        }
      },
      delete: function _delete() {
        Ember.$('.bulk-actions .icon-trash').closest('a').click();
      },


      // Global
      gotoc: function gotoc() {
        this._gotoRoute('global-admin.clusters.index', 'global');
      },
      gotou: function gotou() {
        this._gotoRoute('global-admin.accounts.index', 'global');
      },
      gotoK: function gotoK() {
        this._gotoRoute('authenticated.apikeys', 'global');
      },
      gotoP: function gotoP() {
        this._gotoRoute('authenticated.prefs', 'global');
      },
      gotoT: function gotoT() {
        this._gotoRoute('nodes.node-templates', 'global');
      },


      // Cluster or Project
      gotom: function gotom() {
        this._gotoMembers();
      },


      // Cluster
      gotod: function gotod() {
        this._gotoRoute('authenticated.cluster.index', 'cluster');
      },
      goton: function goton() {
        this._gotoRoute('authenticated.cluster.nodes', 'cluster');
      },
      gotop: function gotop() {
        this._gotoRoute('authenticated.cluster.projects', 'cluster');
      },
      gotoV: function gotoV() {
        this._gotoRoute('authenticated.cluster.storage.persistent-volumes', 'cluster');
      },
      quake: function quake() {
        var clusterId = Ember.get(this, 'scope.currentCluster.id');

        if (clusterId) {
          this.get('modalService').toggleModal('modal-kubectl', {});
        }
      },


      // Project
      gotow: function gotow() {
        this._gotoRoute('containers', 'project');
      },
      gotob: function gotob() {
        this._gotoRoute('ingresses', 'project');
      },
      gotos: function gotos() {
        this._gotoRoute('authenticated.project.dns', 'project');
      },
      gotov: function gotov() {
        this._gotoRoute('volumes', 'project');
      },
      gotoa: function gotoa() {
        this._gotoRoute('apps-tab.index', 'project');
      }
    },

    testAuthToken: function testAuthToken() {
      var _this4 = this;

      return Ember.get(this, 'access').testAuth().catch(function () {
        Ember.set(_this4, 'session.' + _constants.default.SESSION.BACK_TO, window.location.href);
        _this4.transitionTo('login');
        _this4.send('logout', null);
      });
    },
    loadPreferences: function loadPreferences() {
      var _this5 = this;

      return Ember.get(this, 'globalStore').find('preference', null, { url: 'preference' }).then(function (res) {
        Ember.get(_this5, 'language').initLanguage(true);
        Ember.get(_this5, 'userTheme').setupTheme();

        if (Ember.get(_this5, 'prefs.' + _constants.default.PREFS.I_HATE_SPINNERS)) {
          Ember.$('BODY').addClass('i-hate-spinners');
        }

        return res;
      });
    },
    loadClusters: function loadClusters() {
      return Ember.get(this, 'scope').getAllClusters();
    },
    loadProjects: function loadProjects() {
      return Ember.get(this, 'scope').getAllProjects();
    },
    loadPublicSettings: function loadPublicSettings() {
      return Ember.get(this, 'globalStore').find('setting', null, { url: 'settings' });
    },
    loadSecrets: function loadSecrets() {
      if (Ember.get(this, 'store').getById('schema', 'secret')) {
        return Ember.get(this, 'store').find('secret');
      } else {
        return Ember.RSVP.resolve();
      }
    },
    _gotoRoute: function _gotoRoute(name) {
      var scope = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'global';

      // Don't go to routes if in a form page, because you can easily not be on an input
      // and leave the page accidentally.
      if (Ember.$('FORM').length > 0) {
        return;
      }

      var clusterId = Ember.get(this, 'scope.currentCluster.id');
      var projectId = Ember.get(this, 'scope.currentProject.id');

      if (scope === 'cluster' && clusterId) {
        this.transitionTo(name, clusterId);
      } else if (scope === 'project' && projectId) {
        this.transitionTo(name, projectId);
      } else if (scope === 'global') {
        this.transitionTo(name);
      }
    },
    _gotoMembers: function _gotoMembers() {
      var clusterId = Ember.get(this, 'scope.currentCluster.id');
      var projectId = Ember.get(this, 'scope.currentProject.id');

      if (projectId) {
        this._gotoRoute('authenticated.project.security.members.index', 'project');
      } else if (clusterId) {
        this._gotoRoute('authenticated.cluster.security.members.index', 'cluster');
      }
    }
  });
});