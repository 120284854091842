define('ui/models/clusterroletemplatebinding', ['exports', 'ember-api-store/models/resource', 'ember-api-store/utils/denormalize', 'ui/utils/constants', 'ui/mixins/principal-reference'], function (exports, _resource, _denormalize, _constants, _principalReference) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _resource.default.extend(_principalReference.default, {
    type: 'clusterRoleTemplateBinding',

    canEdit: false,
    cluster: (0, _denormalize.reference)('clusterId'),
    roleTemplate: (0, _denormalize.reference)('roleTemplateId'),
    user: (0, _denormalize.reference)('userId', 'user'),
    isCustom: Ember.computed('roleTemplateId', function () {
      return !_constants.default.BASIC_ROLE_TEMPLATE_ROLES.includes(Ember.get(this, 'roleTemplateId'));
    }),

    principalId: Ember.computed('userPrincipalId', 'groupPrincipalId', function () {
      return Ember.get(this, 'groupPrincipalId') || Ember.get(this, 'userPrincipalId') || null;
    }),

    canRemove: Ember.computed('links.remove', 'name', function () {
      return !!Ember.get(this, 'links.remove') && Ember.get(this, 'name') !== 'creator';
    })

  });
});