define('ui/models/podsecuritypolicytemplate', ['exports', 'ember-api-store/models/resource'], function (exports, _resource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _resource.default.extend({
    router: Ember.inject.service(),

    type: 'podSecurityPolicyTemplate',
    actions: {
      edit: function edit() {
        this.get('router').transitionTo('global-admin.security.policies.edit', this.get('id'));
      }
    }
  });
});