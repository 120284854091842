define('ui/models/launchconfig', ['exports', 'ember-api-store/models/resource'], function (exports, _resource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LaunchConfig = _resource.default.extend({
    displayEnvironmentVars: Ember.computed('launchConfig.environment', function () {
      var envs = [];
      var environment = this.get('launchConfig.environment') || {};

      Object.keys(environment).forEach(function (key) {
        envs.pushObject({
          key: key,
          value: environment[key]
        });
      });

      return envs;
    })
  });

  exports.default = LaunchConfig;
});