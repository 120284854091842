define('ui/models/httpingresspath', ['exports', 'ember-api-store/models/resource', 'ember-api-store/utils/denormalize'], function (exports, _resource, _denormalize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var HttpIngressPath = _resource.default.extend({ service: (0, _denormalize.reference)('serviceId') });

  exports.default = HttpIngressPath;
});