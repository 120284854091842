define('ui/models/ingress', ['exports', 'ember-api-store/models/resource', 'ember-api-store/utils/denormalize'], function (exports, _resource, _denormalize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _resource.default.extend({
    clusterStore: Ember.inject.service(),
    router: Ember.inject.service(),

    type: 'ingress',

    canClone: true,
    canHaveLabels: true,

    namespace: (0, _denormalize.reference)('namespaceId', 'namespace', 'clusterStore'),

    targets: Ember.computed('rules.@each.paths', function () {
      var out = [];
      var store = Ember.get(this, 'store');

      var tlsHosts = [];

      (Ember.get(this, 'tls') || []).forEach(function (entry) {
        tlsHosts.addObjects(entry.hosts || []);
      });
      tlsHosts = tlsHosts.uniq();

      var def = Ember.get(this, 'defaultBackend');

      if (def) {
        addRow(null, null, def);
      }

      (Ember.get(this, 'rules') || []).forEach(function (rule) {
        var entries = Ember.get(rule, 'paths') || {};

        Object.keys(entries).forEach(function (path) {
          addRow(rule.host, path, entries[path]);
        });
      });

      function addRow(host, path, entry) {
        var reference = void 0;

        if (entry.serviceId) {
          reference = store.getById('service', entry.serviceId);
          out.push({
            host: host,
            tls: tlsHosts.includes(host),
            path: path,
            reference: entry.serviceId,
            service: reference
          });
        } else if (entry.workloadIds) {
          (entry.workloadIds || []).forEach(function (id) {
            reference = store.getById('workload', id);
            out.push({
              host: host,
              tls: tlsHosts.includes(host),
              path: path,
              reference: id,
              workload: reference
            });
          });
        }
      }

      return out;
    }),

    displayKind: Ember.computed('intl.locale', function () {
      var intl = Ember.get(this, 'intl');

      return intl.t('model.ingress.displayKind');
    }),
    actions: {
      edit: function edit() {
        Ember.get(this, 'router').transitionTo('ingresses.run', {
          queryParams: {
            ingressId: Ember.get(this, 'id'),
            upgrade: true
          }
        });
      },
      clone: function clone() {
        Ember.get(this, 'router').transitionTo('ingresses.run', {
          queryParams: {
            ingressId: Ember.get(this, 'id'),
            upgrade: false
          }
        });
      }
    }

  });
});