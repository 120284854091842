define('ui/authenticated/project/ns/index/controller', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.headers = undefined;
  var headers = exports.headers = [{
    name: 'state',
    sort: ['sortState', 'displayName'],
    searchField: 'displayState',
    translationKey: 'generic.state',
    width: 120
  }, {
    name: 'name',
    sort: ['sortName', 'id'],
    searchField: 'displayName',
    translationKey: 'namespacesPage.table.name.label'
  }, {
    classNames: 'text-right pr-20',
    name: 'created',
    sort: ['created', 'id'],
    searchField: 'created',
    translationKey: 'namespacesPage.table.created.label',
    width: 250
  }];

  exports.default = Ember.Controller.extend({

    scope: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    sortBy: 'name',
    headers: headers,

    actions: {
      newNs: function newNs() {
        Ember.get(this, 'session').set(_constants.default.SESSION.BACK_TO, window.location.href);
        Ember.get(this, 'router').transitionTo('authenticated.cluster.projects.new-ns', Ember.get(this, 'scope.currentCluster.id'), {
          queryParams: {
            addTo: Ember.get(this, 'scope.currentProject.id'),
            from: 'project'
          }
        });
      }
    },

    allNamespace: Ember.computed('model.namespaces.[]', function () {
      var ns = Ember.get(this, 'model.namespaces');
      var pId = Ember.get(this, 'scope.currentProject.id');

      return ns.filter(function (n) {
        return Ember.get(n, 'projectId') === pId || Ember.isEmpty(Ember.get(n, 'projectId'));
      });
    }),

    projectNamespaces: Ember.computed('model.namespaces', function () {
      var _this = this;

      return Ember.get(this, 'model.namespaces').filter(function (ns) {
        return Ember.get(ns, 'projectId') === Ember.get(_this, 'scope.currentProject.id');
      });
    }),

    projectlessNamespaces: Ember.computed('model.namespaces', function () {
      return Ember.get(this, 'model.namespaces').filter(function (ns) {
        return Ember.isEmpty(Ember.get(ns, 'projectId'));
      });
    })

  });
});