define('ui/node/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    model: function model(params) {
      return Ember.get(this, 'globalStore').find('node', params.node_id).then(function (node) {
        return {
          node: node,
          nodes: [node]
        };
      });
    }
  });
});