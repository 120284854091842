define('ui/application/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    settings: Ember.inject.service(),

    resourceActions: Ember.inject.service('resource-actions'),
    tooltipService: Ember.inject.service('tooltip'),

    // GitHub auth params
    queryParams: ['isPopup', 'redirectTo', 'fromAuthProvider'],

    error: null,
    error_description: null,
    state: null,
    code: null,
    isPopup: null,
    redirectTo: null,

    tooltip: Ember.computed.oneWay('tooltipService.tooltipOpts.type'),
    tooltipTemplate: Ember.computed.oneWay('tooltipService.tooltipOpts.template'),

    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('app.environment') === 'development') {
        Ember.run.backburner.DEBUG = true;
      }
    },


    // currentRouteName is set by Ember.Router
    // but getting the application controller to get it is inconvenient sometimes
    currentRouteNameChanged: Ember.observer('currentRouteName', function () {
      this.set('app.currentRouteName', this.get('currentRouteName'));
    })

  });
});