define('ui/components/modal-rollback-app/component', ['exports', 'shared/mixins/modal-base', 'ui/components/modal-rollback-app/template'], function (exports, _modalBase, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var HIDDEN_FIELDS = ['digest'];

  function sanitize(config) {
    HIDDEN_FIELDS.forEach(function (key) {
      if (config.hasOwnProperty(key)) {
        delete config[key];
      }
    });

    return config;
  }

  exports.default = Ember.Component.extend(_modalBase.default, {
    layout: _template.default,

    classNames: ['large-modal'],

    loading: true,
    revisions: null,
    revisionId: null,

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var model = Ember.get(this, 'modalService.modalOpts.originalModel').clone();

      Ember.set(this, 'model', model);
      Ember.get(this, 'store').rawRequest({
        url: Ember.get(model, 'links.revision'),
        method: 'GET'
      }).then(function (revs) {
        Ember.set(_this, 'revisions', revs.body.data);
      }).catch(function (err) {
        _this.send('cancel');
        Ember.get(_this, 'growl').fromError(err);
      }).finally(function () {
        Ember.set(_this, 'loading', false);
      });
    },


    actions: {
      save: function save(cb) {
        var _this2 = this;

        var revisionId = Ember.get(this, 'revisionId');

        Ember.get(this, 'model').doAction('rollback', { revisionId: revisionId }).then(function () {
          _this2.send('cancel');
        }).finally(function () {
          cb();
        });
      }
    },

    choices: Ember.computed('revisions.[]', function () {
      return (Ember.get(this, 'revisions') || []).sortBy('created').reverse().map(function (r) {
        var time = moment(Ember.get(r, 'created'));

        return {
          label: Ember.get(r, 'name') + ': ' + time.format('YYYY-MM-DD HH:mm:ss') + ' (' + time.fromNow() + ')',
          value: Ember.get(r, 'name'),
          data: r
        };
      });
    }),

    current: Ember.computed('choices.[]', function () {
      return Ember.get(this, 'choices.firstObject.data');
    }),

    selected: Ember.computed('revisionId', 'revisions.[]', function () {
      return Ember.get(this, 'revisions').findBy('name', Ember.get(this, 'revisionId'));
    }),

    diff: Ember.computed('current', 'selected', function () {
      if (Ember.get(this, 'current') && Ember.get(this, 'selected')) {
        var left = Ember.get(this, 'current.status');
        var right = Ember.get(this, 'selected.status');
        var delta = jsondiffpatch.diff(sanitize(left), sanitize(right));

        jsondiffpatch.formatters.html.hideUnchanged();

        return jsondiffpatch.formatters.html.format(delta, left).htmlSafe();
      }
    })
  });
});