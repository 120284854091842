define('ui/container/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    router: Ember.inject.service(),

    selectedContainer: null,

    actions: {
      select: function select(container) {
        Ember.set(this, 'selectedContainer', container);
      }
    },

    containerDidChange: Ember.observer('model.containers.[]', function () {
      var _this = this;

      Ember.run.once(function () {
        return Ember.set(_this, 'selectedContainer', Ember.get(_this, 'model.containers.firstObject'));
      });
    }),

    podStateDidChange: Ember.observer('model.state', function () {
      if (Ember.get(this, 'model.state') === 'removed' && Ember.get(this, 'router.currentRouteName') === 'container') {
        var workloadId = Ember.get(this, 'model.workloadId');

        if (workloadId) {
          this.transitionToRoute('workload', workloadId);
        } else {
          this.transitionToRoute('authenticated.project.index');
        }
      }
    }),
    displayEnvironmentVars: Ember.computed('selectedContainer', function () {
      var envs = [];
      var environment = this.get('selectedContainer.environment') || {};

      Object.keys(environment).forEach(function (key) {
        envs.pushObject({
          key: key,
          value: environment[key]
        });
      });

      return envs;
    })

  });
});