define('ui/models/storageclass', ['exports', 'ember-api-store/models/resource'], function (exports, _resource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.registerProvisioner = registerProvisioner;
  exports.getProvisioners = getProvisioners;


  var BETA_ANNOTATION = 'storageclass.beta.kubernetes.io/is-default-class';
  var DEFAULT_ANNOTATION = 'storageclass.kubernetes.io/is-default-class';

  var PROVISIONERS = [];

  registerProvisioner('aws-ebs', 'kubernetes.io/aws-ebs', true);
  registerProvisioner('gce-pd', 'kubernetes.io/gce-pd', true);
  registerProvisioner('glusterfs', 'kubernetes.io/glusterfs', true);
  registerProvisioner('cinder', 'kubernetes.io/cinder', true);
  registerProvisioner('vsphere-volume', 'kubernetes.io/vsphere-volume', true);
  registerProvisioner('rbd', 'kubernetes.io/rbd', true);
  registerProvisioner('quobyte', 'kubernetes.io/quobyte', true);
  registerProvisioner('azure-disk', 'kubernetes.io/azure-disk', true);
  registerProvisioner('azure-file', 'kubernetes.io/azure-file', true);
  registerProvisioner('portworx-volume', 'kubernetes.io/portworx-volume', true);
  registerProvisioner('scaleio', 'kubernetes.io/scaleio', true);
  registerProvisioner('storageos', 'kubernetes.io/storageos', true);
  registerProvisioner('longhorn', 'rancher.io/longhorn', true);

  function registerProvisioner(name, provisioner, component) {
    if (component === true) {
      component = name;
    }

    var existing = PROVISIONERS.findBy('name', name);

    if (existing) {
      PROVISIONERS.removeObject(existing);
    }

    PROVISIONERS.push({
      name: name,
      value: provisioner,
      component: component
    });
  }

  function getProvisioners() {
    return JSON.parse(JSON.stringify(PROVISIONERS));
  }

  exports.default = _resource.default.extend({
    clusterStore: Ember.inject.service(),
    router: Ember.inject.service(),

    type: 'storageClass',
    state: 'active',

    isDefault: Ember.computed('annotations', function () {
      var annotations = Ember.get(this, 'annotations') || {};

      return annotations[DEFAULT_ANNOTATION] === 'true' || annotations[BETA_ANNOTATION] === 'true';
    }),

    availableActions: Ember.computed('isDefault', function () {
      var isDefault = Ember.get(this, 'isDefault');

      var out = [{
        label: 'action.makeDefault',
        icon: 'icon icon-star-fill',
        action: 'makeDefault',
        enabled: !isDefault
      }, {
        label: 'action.resetDefault',
        icon: 'icon icon-star-line',
        action: 'resetDefault',
        enabled: isDefault
      }];

      return out;
    }),

    displayProvisioner: Ember.computed('provisioner', 'intl.locale', function () {
      var intl = Ember.get(this, 'intl');
      var provisioner = Ember.get(this, 'provisioner');
      var entry = PROVISIONERS.findBy('value', provisioner);

      if (provisioner && entry) {
        var key = 'storageClass.' + entry.name + '.title';

        if (intl.exists(key)) {
          return intl.t(key);
        }
      }

      return provisioner;
    }),
    actions: {
      makeDefault: function makeDefault() {
        var _this = this;

        var cur = Ember.get(this, 'clusterStore').all('storageClass').filterBy('isDefault', true);
        var promises = [];

        cur.forEach(function (sc) {
          promises.push(sc.setDefault(false));
        });

        Ember.RSVP.all(promises).then(function () {
          _this.setDefault(true);
        });
      },
      resetDefault: function resetDefault() {
        this.setDefault(false);
      },
      edit: function edit() {
        Ember.get(this, 'router').transitionTo('authenticated.cluster.storage.classes.detail.edit', Ember.get(this, 'id'));
      }
    },

    setDefault: function setDefault(on) {
      var annotations = Ember.get(this, 'annotations') || {};

      if (on) {
        annotations[DEFAULT_ANNOTATION] = 'true';
        annotations[BETA_ANNOTATION] = 'true';
      } else {
        annotations[DEFAULT_ANNOTATION] = 'false';
        annotations[BETA_ANNOTATION] = 'false';
      }

      this.save();
    }
  });
});