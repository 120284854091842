define('ui/components/form-healthcheck/component', ['exports', 'ui/components/form-healthcheck/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NONE = 'none';
  var TCP = 'tcp';
  var HTTP = 'http';
  var HTTPS = 'https';
  var COMMAND = 'command';

  exports.default = Ember.Component.extend({
    scope: Ember.inject.service(),
    intl: Ember.inject.service(),
    settings: Ember.inject.service(),

    layout: _template.default,
    // Inputs
    healthCheck: null,
    errors: null,
    dnsNote: false,
    successMustBeOne: false,

    editing: true,

    checkType: null,
    command: null,
    path: null,
    host: null,
    headers: null,

    isNone: Ember.computed.equal('checkType', NONE),
    isTcp: Ember.computed.equal('checkType', TCP),
    isHttp: Ember.computed.equal('checkType', HTTP),
    isHttps: Ember.computed.equal('checkType', HTTPS),
    isHttpish: Ember.computed.or('isHttp', 'isHttps'),
    isCommand: Ember.computed.equal('checkType', COMMAND),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var initial = Ember.get(this, 'initialCheck');
      var check = void 0;
      var type = NONE;

      if (initial) {
        check = Object.assign({}, initial);
        if (Ember.get(check, 'tcp')) {
          type = TCP;
        } else if (Ember.get(check, 'command.length')) {
          type = COMMAND;
          Ember.set(this, 'command', Ember.get(check, 'command'));
        } else if (Ember.get(check, 'scheme') === 'HTTP') {
          type = HTTP;
        } else if (Ember.get(check, 'scheme') === 'HTTPS') {
          type = HTTPS;
        }

        if (type === HTTP || type === HTTPS) {
          var originalHeaders = Ember.get(check, 'httpHeaders') || {};
          var host = null;
          var headers = {};

          Object.keys(originalHeaders).forEach(function (key) {
            if (key.toLowerCase() === 'host') {
              host = originalHeaders[key];
            } else {
              headers[key] = originalHeaders[key];
            }
          });

          Ember.set(this, 'path', Ember.get(check, 'path'));
          Ember.set(this, 'host', host);
          Ember.set(this, 'headers', headers);
        }
      } else {
        check = Ember.get(this, 'store').createRecord({
          type: 'probe',
          failureThreshold: 3,
          initialDelaySeconds: 10,
          path: '/',
          periodSeconds: 2,
          successThreshold: 2,
          timeoutSeconds: 2
        });
      }

      if (Ember.get(this, 'successMustBeOne')) {
        Ember.set(check, 'successThreshold', 1);
      }

      Ember.set(this, 'healthCheck', check);
      Ember.set(this, 'checkType', type);
      this.validate();

      Ember.run.scheduleOnce('afterRender', function () {
        _this.checkChanged();
      });
    },


    checkChanged: Ember.observer('path', 'host', 'headers', 'checkType', 'command', function () {
      var check = Ember.get(this, 'healthCheck');

      if (Ember.get(this, 'isNone')) {
        this.sendAction('changed', null);

        return;
      }

      Ember.setProperties(check, { tcp: Ember.get(this, 'isTcp') });

      if (Ember.get(this, 'isHttpish')) {
        var host = Ember.get(this, 'host');
        var hostHeader = {};

        if (host) {
          hostHeader['Host'] = host;
        }

        var headers = Object.assign({}, hostHeader, Ember.get(this, 'headers'));

        Ember.setProperties(check, {
          httpHeaders: headers,
          path: Ember.get(this, 'path') || '/',
          scheme: Ember.get(this, 'isHttps') ? 'HTTPS' : 'HTTP'
        });
      } else {
        Ember.setProperties(check, {
          path: null,
          httpHeaders: null
        });
      }

      if (Ember.get(this, 'isCommand')) {
        Ember.set(check, 'command', Ember.get(this, 'command'));
      } else {
        Ember.set(check, 'command', null);
      }

      this.sendAction('changed', check);
    }),

    validate: Ember.observer('isNone', 'isCommand', 'healthCheck.command.[]', 'healthCheck.port', function () {
      var errors = [];

      Ember.set(this, 'errors', errors);

      if (Ember.get(this, 'isNone')) {
        return;
      }

      if (Ember.get(this, 'isCommand')) {
        if (!Ember.get(this, 'healthCheck.command.length')) {
          errors.push('Health Check command is required');
        }
      } else {
        if (!Ember.get(this, 'healthCheck.port')) {
          errors.push('Health Check port is required');
        }
      }
    })
  });
});