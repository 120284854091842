define('ui/models/catalog', ['exports', 'ember-api-store/models/resource', 'shared/utils/util', 'ui/utils/constants'], function (exports, _resource, _util, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _resource.default.extend({
    modalService: Ember.inject.service('modal'),

    displayKind: Ember.computed('kind', function () {
      return (0, _util.ucFirst)(Ember.get(this, 'kind'));
    }),

    canClone: Ember.computed('actions.clone', function () {
      var name = Ember.get(this, 'name');
      var catalogNames = Ember.get(_constants.default, 'CATALOG');
      var builtIn = [Ember.get(catalogNames, 'LIBRARY_KEY'), Ember.get(catalogNames, 'HELM_STABLE_KEY'), Ember.get(catalogNames, 'HELM_INCUBATOR_KEY')];

      return !builtIn.includes(name);
    }),

    actions: {
      edit: function edit() {
        this.get('modalService').toggleModal('modal-edit-catalog', this);
      },
      clone: function clone() {
        var clone = this.cloneForNew();

        this.get('modalService').toggleModal('modal-edit-catalog', clone);
      }
    }

  });
});