define('ui/authenticated/cluster/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    modalService: Ember.inject.service('modal'),
    scope: Ember.inject.service(),
    k8s: Ember.inject.service(),

    projectController: Ember.inject.controller('authenticated.project'),
    tags: Ember.computed.alias('projectController.tags'),

    actions: {
      dashboard: function dashboard() {
        //    window.open(this.get('k8s.kubernetesDashboard'),'_blank');
      },
      kubectl: function kubectl() {
        /* @TODO-2.0
        if (e.metaKey) {
          let proj = this.get('scope.currentProject.id');
          later(() => {
            window.open(`//${window.location.host}/env/${proj}/infra/console?kubernetes=true&isPopup=true`, '_blank', "toolbars=0,width=900,height=700,left=200,top=200");
          });
        } else {
        */
        this.get('modalService').toggleModal('modal-kubectl', {});
        //     }
      },
      kubeconfig: function kubeconfig() {
        this.get('modalService').toggleModal('modal-kubeconfig', { escToClose: true });
      }
    },

    currentClusterNodes: Ember.computed('model.nodes.@each.{capacity,allocatable,state,isUnschedulable}', function () {
      var clusterId = Ember.get(this, 'scope.currentCluster.id');

      return Ember.get(this, 'model.nodes').filter(function (n) {
        return n.clusterId === clusterId && !n.isUnschedulable;
      });
    })
  });
});