define('ui/components/project-table/component', ['exports', 'ui/components/project-table/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var headers = [{
    name: 'state',
    sort: ['hasProject', 'sortState', 'displayName'],
    searchField: 'displayState',
    translationKey: 'generic.state',
    width: 120
  }, {
    name: 'name',
    sort: ['hasProject', 'displayName'],
    searchField: ['displayName'],
    translationKey: 'projectsPage.name.label'
  }, {
    name: 'created',
    sort: ['hasProject', 'createdTs', ' id'],
    translationKey: 'projectsPage.created.label',
    width: 200
  }];

  exports.default = Ember.Component.extend({
    layout: _template.default,
    headers: headers,
    tagName: '',
    sortBy: 'name'
  });
});