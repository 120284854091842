define('ui/components/catalog-index/component', ['exports', 'shared/utils/constants', 'ui/components/catalog-index/template'], function (exports, _constants, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    catalog: Ember.inject.service(),
    settings: Ember.inject.service(),
    scope: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    layout: _template.default,
    search: '',
    parentRoute: null,
    launchRoute: null,
    updating: 'no',

    projectId: Ember.computed.alias('cookies.' + _constants.default.COOKIE.PROJECT),
    categories: Ember.computed.alias('model.categories'),
    init: function init() {
      this._super.apply(this, arguments);
      this.get('catalog.componentRequestingRefresh');
    },


    actions: {
      clearSearch: function clearSearch() {
        this.set('search', '');
      },
      update: function update() {
        var _this = this;

        this.set('updating', 'yes');
        this.get('catalog').refresh().then(function () {
          _this.set('updating', 'no');
          _this.sendAction('refresh');
        }).catch(function () {
          _this.set('updating', 'error');
        });
      }
    },

    childRequestiongRefresh: Ember.observer('catalog.componentRequestingRefresh', function () {
      if (this.get('catalog.componentRequestingRefresh')) {
        this.send('update');
      }
    }),

    totalCategories: Ember.computed('categoryWithCounts', function () {
      var categories = this.get('categoryWithCounts');
      var count = 0;

      Object.keys(categories).forEach(function (cat) {
        count = count + categories[cat].count;
      });

      return count;
    }),

    categoryWithCounts: Ember.computed('category', 'categories', function () {
      var categories = [];
      var out = {};
      var templates = this.get('catalog.templateCache');

      templates.forEach(function (tpl) {
        if (tpl.categories) {
          tpl.categories.forEach(function (ctgy) {
            categories.push(ctgy);
          });
        }
      });

      categories.sort().forEach(function (ctgy) {
        var normalized = ctgy.underscore();

        if (out[normalized] && ctgy) {
          out[normalized].count++;
        } else {
          out[normalized] = {
            name: ctgy,
            category: normalized,
            count: 1
          };
        }
      });

      var list = [];

      Object.keys(out).forEach(function (key) {
        list.push(out[key]);
      });

      return list;
    }),

    catalogURL: Ember.computed('model.catalogs', function () {
      var neu = { catalogs: {} };

      this.get('model.catalogs').forEach(function (cat) {
        neu.catalogs[cat.id] = {
          branch: cat.branch,
          url: cat.url
        };
      });

      return JSON.stringify(neu);
    }),

    filters: Ember.computed('model.catalogs', function () {
      return this.get('globalStore').all('catalog').map(function (obj) {
        return {
          catalogId: Ember.get(obj, 'id'),
          label: Ember.get(obj, 'name')
        };
      });
    }),

    arrangedContent: Ember.computed('model.catalog', 'search', function () {
      var search = this.get('search').toUpperCase();
      var result = [];
      var catalog = this.get('model.catalog');

      if (!search) {
        return catalog;
      }

      catalog.forEach(function (item) {
        if (item.name.toUpperCase().indexOf(search) >= 0 || item.description.toUpperCase().indexOf(search) >= 0) {
          result.push(item);
        }
      });

      return result;
    })
  });
});