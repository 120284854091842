define('ui/models/template', ['exports', 'ember-api-store/models/resource', 'ui/utils/constants'], function (exports, _resource, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var Template = _resource.default.extend({
    scope: Ember.inject.service(),
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),

    headers: function () {
      return _defineProperty({}, _constants.default.HEADER.PROJECT_ID, this.get('projects.current.id'));
    }.property('project.current.id'),

    cleanProjectUrl: Ember.computed('links.project', function () {
      var projectUrl = this.get('links.project');
      var pattern = new RegExp('^([a-z]+://|//)', 'i');

      if (projectUrl) {
        if (!pattern.test(projectUrl)) {
          projectUrl = 'http://' + projectUrl;
        }
      }

      return Ember.String.htmlSafe(projectUrl);
    }),

    categoryArray: function () {
      var out = this.get('categories');

      if (!out || !out.length) {
        var single = this.get('category');

        if (single) {
          out = [single];
        } else {
          out = [];
        }
      }

      return out;
    }.property('category', 'categories.[]'),

    categoryLowerArray: function () {
      return this.get('categoryArray').map(function (x) {
        return (x || '').underscore().toLowerCase();
      });
    }.property('categoryArray.[]'),

    certifiedType: function () {
      var str = null;
      var labels = this.get('labels');

      if (labels && labels[_constants.default.LABEL.CERTIFIED]) {
        str = labels[_constants.default.LABEL.CERTIFIED];
      }

      if (str === _constants.default.LABEL.CERTIFIED_RANCHER && this.get('catalogId') === _constants.default.CATALOG.LIBRARY_KEY) {
        return 'rancher';
      } else if (str === _constants.default.LABEL.CERTIFIED_PARTNER) {
        return 'partner';
      } else {
        return 'thirdparty';
      }
    }.property('catalogId'),

    certifiedClass: function () {
      var type = this.get('certifiedType');

      if (type === 'rancher' && this.get('settings.isRancher')) {
        return 'badge-rancher-logo';
      } else {
        return 'badge-' + type;
      }
    }.property('certifiedType'),

    certified: function () {
      var out = null;
      var labels = this.get('labels');

      if (labels && labels[_constants.default.LABEL.CERTIFIED]) {
        out = labels[_constants.default.LABEL.CERTIFIED];
      }

      var looksLikeCertified = false;

      if (out) {
        var display = this.get('intl').t('catalogPage.index.certified.rancher.rancher');

        looksLikeCertified = normalize(out) === normalize(display);
      }

      if (this.get('catalogId') !== _constants.default.CATALOG.LIBRARY_KEY && (out === _constants.default.LABEL.CERTIFIED_RANCHER || looksLikeCertified)) {
        // Rancher-certified things can only be in the library catalog.
        out = null;
      }

      // For the standard labels, use translations
      if ([_constants.default.LABEL.CERTIFIED_RANCHER, _constants.default.LABEL.CERTIFIED_PARTNER].includes(out)) {
        var pl = 'pl';

        if (this.get('settings.isRancher')) {
          pl = 'rancher';
        }

        return this.get('intl').t('catalogPage.index.certified.' + pl + '.' + out);
      }

      // For custom strings, use what they said.
      return out;
    }.property('certifiedType', 'catalogId', 'labels')

  });

  function normalize(str) {
    return (str || '').replace(/[^a-z]/gi, '').toLowerCase();
  }

  exports.default = Template;
});