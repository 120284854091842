define('ui/catalog-tab/route', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = Ember.Route.extend({
    access: Ember.inject.service(),
    catalog: Ember.inject.service(),
    scope: Ember.inject.service(),

    beforeModel: function beforeModel() {
      var _this = this;

      this._super.apply(this, arguments);

      return Ember.RSVP.hash({ catalogs: this.get('catalog').fetchCatalogs({ headers: _defineProperty({}, _constants.default.HEADER.PROJECT_ID, this.get('scope.currentProject.id')) }) }).then(function (hash) {
        _this.set('catalogs', hash.catalogs);
      });
    },
    model: function model(params) {
      var _this2 = this;

      var project = this.modelFor('authenticated.project').get('project');

      return this.get('catalog').fetchTemplates(params).then(function (res) {
        res.catalog.forEach(function (tpl) {
          var exists = project.get('apps').findBy('externalIdInfo.templateId', tpl.get('id'));

          tpl.set('exists', !!exists);
        });
        res.catalogs = _this2.get('catalogs');

        return res;
      });
    },
    resetController: function resetController(controller, isExiting /* , transition*/) {
      if (isExiting) {
        controller.set('category', '');
        controller.set('catalogId', '');
      }
    },
    deactivate: function deactivate() {
      // Clear the cache when leaving the route so that it will be reloaded when you come back.
      this.set('cache', null);
    },


    actions: {
      refresh: function refresh() {
        // Clear the cache so it has to ask the server again
        this.set('cache', null);
        this.refresh();
      }
    },

    queryParams: {
      category: { refreshModel: true },
      catalogId: { refreshModel: true }
    }

  });
});