define('ui/components/input-edit-password/component', ['exports', 'ui/components/input-edit-password/template', 'shared/utils/util'], function (exports, _template, _util) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CHANGE = 'change';
  var SET = 'set';

  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    access: Ember.inject.service(),

    layout: _template.default,
    showCurrent: true,
    generate: false,
    setOrChange: CHANGE,
    editLabel: 'modalEditPassword.actionButton',
    currentPassword: null,
    user: null,

    confirmBlurred: false,
    serverErrors: null,
    password: null,
    confirm: null,

    didReceiveAttrs: function didReceiveAttrs() {
      if (Ember.get(this, 'generate')) {
        this.send('regenerate');
      }

      Ember.run.next(this, 'focusStart');
    },


    actions: {
      regenerate: function regenerate() {
        this.generateChanged();
      },
      blurredConfirm: function blurredConfirm() {
        Ember.set(this, 'confirmBlurred', true);
      },
      save: function save(cb) {
        var _this = this;

        var user = Ember.get(this, 'user');
        var old = Ember.get(this, 'currentPassword') || '';
        var neu = Ember.get(this, 'password') || '';

        Ember.set(this, 'serverErrors', []);

        var setOrChange = Ember.get(this, 'setOrChange');
        var promise = void 0;

        if (setOrChange === CHANGE) {
          // @TODO-2.0 better way to call collection actions
          promise = Ember.get(this, 'globalStore').request({
            url: '/v3/users?action=changepassword',
            method: 'POST',
            data: {
              currentPassword: old.trim(),
              newPassword: neu.trim()
            }
          });
        } else if (setOrChange === SET) {
          promise = user.doAction('setpassword', { newPassword: neu.trim() });
        }

        return promise.then(function () {
          return Ember.get(_this, 'access').loadMe().then(function () {
            Ember.get(_this, 'complete')(true);
            Ember.run.later(_this, function () {
              if (_this.isDestroyed || _this.isDestroying) {
                return;
              }
              cb(true);
            }, 1000);
          });
        }).catch(function (err) {
          Ember.set(_this, 'serverErrors', [err.message]);
          Ember.get(_this, 'complete')(false);
          cb(false);
        });
      }
    },
    generateChanged: Ember.observer('generate', function () {
      if (Ember.get(this, 'generate')) {
        Ember.set(this, 'password', (0, _util.randomStr)(16, 'password'));
      } else {
        Ember.set(this, 'password', '');
        Ember.set(this, 'confirm', '');
        Ember.run.next(this, 'focusStart');
      }
    }),

    saveDisabled: Ember.computed('generate', 'password', 'confirm', function () {
      if (Ember.get(this, 'generate')) {
        return false;
      }

      var pass = (Ember.get(this, 'password') || '').trim();
      var confirm = (Ember.get(this, 'confirm') || '').trim();

      return !pass || !confirm || pass !== confirm;
    }),

    errors: Ember.computed('saveDisabled', 'confirm', 'confirmBlurred', 'serverErrors.[]', function () {
      var out = Ember.get(this, 'serverErrors') || [];

      if (Ember.get(this, 'confirmBlurred') && Ember.get(this, 'confirm') && Ember.get(this, 'saveDisabled')) {
        out.push(Ember.get(this, 'intl').t('modalEditPassword.mismatch'));
      }

      return out;
    }),

    focusStart: function focusStart() {
      var elem = $('.start')[0]; // eslint-disable-line

      if (elem) {
        elem.focus();
      }
    }
  });
});